import "./contact.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Banner from "./Banner-contact.png";
import enGovList from "../../i18next-icu/local-data/en.json";
import { FaWhatsapp } from "react-icons/fa6";
import emailjs from "@emailjs/browser";

import { useRef } from "react";
const Contact = () => {
  const { t } = useTranslation();
  const form = useRef();
  const [formSubmitMessage, setFormSubmitMessage] = useState("");
  const [userData, setuserData] = useState({
    name: "",
    email: "",
    governorate: "",
    phoneNumber: "",
    message: "",
  });

  // Rendering the list of Governorates DYNAMICALLY instead of repeating the option tags.
  const objOfGovs = Object.entries(enGovList.translation);
  const arrGovList = objOfGovs.slice(-27).map((item) => item.at(0));
  const selectOptionList = arrGovList.map((item) => {
    return (
      <option key={item} value={t(`${item}`)}>
        {t(`${item}`)}
      </option>
    );
  });

  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    setuserData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    // submitToApi(formData)
    // console.log(userData);
    // alert("Your Feedback has been submitted successfully");
    // setuserData({
    //   name: "",
    //   email: "",
    //   governorate: "",
    //   phoneNumber: "",
    //   message: "",
    // });
    emailjs
      .sendForm(
        "service_c4r6t5o",
        "template_7on05md",
        form.current,
        "-OTvgrbBnoYDrOOI7"
      )
      .then(
        (result) => {
          console.log(result.text);
          form.current.reset();
          setuserData({
            name: "",
            email: "",
            governorate: "",
            phoneNumber: "",
            message: "",
          });
          setFormSubmitMessage("Email sent successfully");
          setTimeout(() => {
            setFormSubmitMessage("");
          }, 3000);
        },
        (error) => {
          console.error(error.text);
          setFormSubmitMessage(
            "There was a problem sending your message. Please try again later."
          );
        }
      );
  }
  return (
    <div className="mb-md-5">
      <div className="container-fluid mb-md-5">
        <div className="mb-5 row">
          <div className="col-12 p-0 position-relative">
            <img className="banner-about-and-contact" src={Banner} alt="" />
            {localStorage.getItem("i18nextLng") === "ar" ? (
              <h2 className="img-overlay-cont-ar">{t("contactUsImage")}</h2>
            ) : (
              <h2 className="img-overlay">{t("contactUsImage")}</h2>
            )}
          </div>
        </div>
      </div>
      <br />
      <br />

      <div className="container mb-md-5 mb-3">
        <div className="row d-flex justify-content-center">
          <div className="col-md-8 col-10">
            <div className="row mb-md-5 my-4">
              <h2 className="defaultColor fw-bold">{t("contactTitle")}</h2>
            </div>
            <div className="row mb-md-5  mb-1">
              <div className="col-md-4 col-12 mb-5 contact-aside text-white p-3">
                <div className="row gy-0 p-4">
                  <div className="col-12 gap-3 mb-4 d-flex align-items-center justify-content-center">
                    <span className="col-3">
                      <svg
                        width="52"
                        height="52"
                        viewBox="0 0 52 52"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="26" cy="26" r="26" fill="#0BADC6" />
                        <path
                          d="M26.0001 13C20.8738 13 16.7144 16.7439 16.7144 21.3571C16.7144 28.7857 26.0001 39 26.0001 39C26.0001 39 35.2858 28.7857 35.2858 21.3571C35.2858 16.7439 31.1264 13 26.0001 13ZM26.0001 26C25.2655 26 24.5473 25.7822 23.9365 25.374C23.3257 24.9659 22.8496 24.3858 22.5685 23.7071C22.2874 23.0284 22.2138 22.2816 22.3572 21.5611C22.5005 20.8406 22.8542 20.1788 23.3737 19.6593C23.8931 19.1399 24.5549 18.7861 25.2754 18.6428C25.9959 18.4995 26.7428 18.573 27.4215 18.8542C28.1002 19.1353 28.6803 19.6114 29.0884 20.2222C29.4965 20.833 29.7144 21.5511 29.7144 22.2857C29.7133 23.2705 29.3216 24.2146 28.6253 24.9109C27.9289 25.6073 26.9848 25.9989 26.0001 26Z"
                          stroke="white"
                          strokeWidth="2"
                        />
                      </svg>
                    </span>
                    <span className="col-11">{t("contactAddress")}</span>
                  </div>

                  {/* I REDUCED THE HEIGHT OF THE BLUE BOX HOLDING CONTACT INFO SO I HAVE TO PUT IT BACK AFTER RE-ADDING THE DOMAIN AGAIN */}
                  {/* <div className="col-12 gap-3 d-flex mb-4 align-items-center justify-content-center">
                    <span className="col-3">
                      <svg
                        width="52"
                        height="52"
                        viewBox="0 0 52 52"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="26" cy="26" r="26" fill="#0BADC6" />
                        <path
                          d="M34.8846 18H17.1154C15.9471 18 15 18.9514 15 20.125V32.875C15 34.0486 15.9471 35 17.1154 35H34.8846C36.0529 35 37 34.0486 37 32.875V20.125C37 18.9514 36.0529 18 34.8846 18Z"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18 21L26 28L34 21"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>

                    <span className="col-11">mountainview.foundation/beta</span>
                  </div> */}
                  <div className="col-12 gap-3 d-flex mb-4 align-items-center justify-content-center">
                    <span
                      className="col-3 d-flex justify-content-center p-2"
                      style={{
                        backgroundColor: "#0badc6",
                        borderRadius: "50%",
                      }}
                    >
                      <FaWhatsapp
                        size={37}
                        className="rounded-circle "
                        fill="#FFF"
                      />
                    </span>

                    <span className="col-11">{t("contactMobile")}</span>
                  </div>
                </div>
                <div className="mb-5">
                  <div className="d-flex justify-content-center gap-5">
                    <a
                      className=""
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/FoundationMV"
                    >
                      <i className="bi-facebook text-white fs-1"></i>
                    </a>
                    <a href="" target="_blank" rel="noreferrer">
                      <i className="bi-linkedin text-white fs-1"></i>
                    </a>
                    {/* <i className="text-white bi-twitter  fs-4"></i> */}
                    {/* <i className="text-white bi-instagram  fs-4"></i> */}
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-12 px-md-5 px-0">
                <form ref={form} onSubmit={handleSubmit}>
                  <div className="row mb-3 mt-3">
                    <p>{t("contactInfo")}</p>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6 col-12 mb-md-2 mb-4">
                      <label htmlFor="name" className="form-label">
                        {t("contactName")}
                      </label>
                      <input
                        type="text"
                        name="name"
                        // value={userData.name}
                        // onChange={handleChange}
                        className="form-control"
                        id="name"
                        required
                      />
                    </div>
                    <div className="col-md-6 col-12 mb-md-2 mb-4">
                      <label htmlFor="email" className="form-label">
                        {t("contactEmail")}
                      </label>
                      <input
                        type="email"
                        name="email"
                        // value={userData.email}
                        // onChange={handleChange}
                        className="form-control"
                        id="email"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6 col-12 mb-md-2 mb-4">
                      <label htmlFor="governorate" className="mb-2">
                        {t("signUpGovernorate")}
                      </label>
                      <div className="d-flex gap-2">
                        <select
                          // value={userData.governorate}
                          // onChange={handleChange}
                          name="governorate"
                          className="w-100 px-2"
                          id="governorate"
                          required
                        >
                          <option>{t("signUpSelectGovernorate")}</option>
                          {selectOptionList}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6 col-12 mb-md-2 mb-4">
                      <label htmlFor="phoneNumber" className="form-label">
                        {t("contactPhone")}
                      </label>
                      <input
                        type="text"
                        name="phoneNumber"
                        // value={userData.phoneNumber}
                        // onChange={handleChange}
                        className="form-control"
                        id="phoneNumber"
                        required
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-12">
                      <label htmlFor="message" className="form-label">
                        {t("contactMessage")} *
                      </label>
                      <textarea
                        // value={userData.message}
                        // onChange={handleChange}
                        name="message"
                        id="message"
                        className="w-100 p-3"
                        rows="5"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <button className="btn-default border-0 py-2 px-5 fw-bold">
                        {t("contactBtn")}{" "}
                        {localStorage.getItem("i18nextLng") === "en" ? (
                          <span className="rightArrow">&rarr;</span>
                        ) : (
                          <span className="rightArrow">&larr;</span>
                        )}
                      </button>
                    </div>
                  </div>
                  {formSubmitMessage && (
                    <center className="text-success ">
                      <p> {formSubmitMessage}</p>
                    </center>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
