import React, { useState, useContext, useEffect } from "react";
// import { customerContext } from "../../axios";
import SignUpSuccess from "./SignUpSuccess";
import { useTranslation } from "react-i18next";
import Banner from "./signupImgs/Screenshot 2024-04-15 at 4.28.06 PM.png";
import SchoolLogo from "./signupImgs/Asset 3.png";
import enData from "../../i18next-icu/local-data/en.json";
import { customerContext } from "../../axios";

const Signup = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const { t } = useTranslation();
  const { setRegisterData } = useContext(customerContext);

  const [userData, setuserData] = useState({
    student_name: "",
    national_id: "",
    phone_number: "",
    school_name: "",
    gender: "",
    governate: "",
    email: "",
    how_you_knew: "",
    year_grade: "",
  });

  // Rendering the list of Governorates DYNAMICALLY instead of repeating the option tags.
  const objOfGovs = Object.entries(enData.translation);
  const arrGovList = objOfGovs.slice(-27).map((item) => item.at(0));

  const selectOptionList = arrGovList.map((item) => {
    return (
      <option key={item} value={t(`${item}`)}>
        {t(`${item}`)}
      </option>
    );
  });

  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    setuserData((prevFormData) => {
      return {
        ...prevFormData,
        // [name]: type === "checkbox" ? checked : value,
        [name]: value,
      };
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    // console.log(userData);
    setRegisterData(userData, 2);
    setShowSuccess(true);
    window.scrollTo(0, 0);
  }

  return showSuccess &&
    !FormData.student_name &&
    !FormData.national_id &&
    !FormData.phone_number &&
    !FormData.school_name &&
    !FormData.gender &&
    !FormData.year_grade &&
    !FormData.governate &&
    !FormData.how_you_knew &&
    !FormData.email ? (
    <SignUpSuccess />
  ) : (
    <>
      <div className="mb-md-5">
        <div className="container-fluid mb-md-5 mb-4">
          <div className="mb-5 row">
            <div className="col-12 p-0 position-relative">
              <img src={Banner} className=" banner-about-and-contact" alt="" />
              {localStorage.getItem("i18nextLng") === "en" ? (
                <h2 className="img-overlay">{t("signUpRegisterNowImage")}</h2>
              ) : (
                <h2 className="img-overlay-cont-ar text-nowrap">
                  {t("signUpRegisterNowImage")}
                </h2>
              )}
            </div>
          </div>
        </div>
        <br />
        <div className="container mb-md-5">
          <form action="" onSubmit={handleSubmit}>
            <div className="row d-flex justify-content-center mb-5">
              <div className="col-md-8 p-0 col-10">
                <div className="row">
                  <img
                    src={SchoolLogo}
                    className="img-fluid "
                    alt="School Logo"
                    style={{ width: "350px" }}
                  />
                </div>
              </div>
            </div>

            <div className="row d-flex justify-content-center">
              <div className="col-md-8 p-0 col-10">
                <div className="row">
                  <h2 className="mb-5 defaultColor fw-bold">
                    {" "}
                    استماره التحاق بمدرسه ماونتن فيو الدوليه للتكنولوجيا
                    التطبيقيه
                  </h2>
                </div>
                <div className="row mb-4">
                  <h3 className="defaultColor">{t("signUpPersonalInfo")}</h3>
                </div>
                <div className="row mb-4">
                  <div className="col-12 col-md-6 mb-md-3 mb-4 ">
                    <label htmlFor="studentName" className="form-label">
                      {" "}
                      {t("signUpStudentName")}{" "}
                    </label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      id="studentName"
                      value={userData.student_name || ""}
                      name="student_name"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 col-md-6 mb-md-3 mb-4 ">
                    <label htmlFor="nationalID" className="form-label">
                      {" "}
                      {t("signUpNationalIDnumber")}{" "}
                    </label>
                    <input
                      type="text"
                      required
                      name="national_id"
                      value={userData.national_id || ""}
                      onChange={handleChange}
                      className="form-control"
                      id="nationalID"
                    />
                  </div>
                  <div className="col-12 col-md-6 mb-md-3 mb-4 ">
                    <label htmlFor="phoneNumber" className="form-label">
                      {" "}
                      {t("signUpPhoneNumber")}{" "}
                    </label>
                    <input
                      type="text"
                      required
                      name="phone_number"
                      value={userData.phone_number || ""}
                      onChange={handleChange}
                      className="form-control"
                      id="phoneNumber"
                    />
                  </div>
                  <div className="col-12 col-md-6 mb-md-3 mb-4 ">
                    <label htmlFor="email" className="form-label">
                      {" "}
                      {t("signUpEmail")}{" "}
                    </label>
                    <input
                      type="email"
                      name="email"
                      required
                      value={userData.email || ""}
                      onChange={handleChange}
                      className="form-control"
                      id="email"
                    />
                  </div>
                  <div className="col-12 col-md-6 mb-md-3 mb-4 ">
                    <label htmlFor="year_grade" className="mb-2 text-nowrap">
                      النسبة المئوية للشهادة الإعدادية
                      <br />
                      (مجموع الترم الأول و الثانى) 2022/2023*
                    </label>
                    <div className="d-flex gap-2">
                      <select
                        name="year_grade"
                        required
                        value={userData.year_grade || ""}
                        onChange={handleChange}
                        className="w-100 px-2"
                        id="year_grade"
                      >
                        <option value=""> --اختر-- </option>
                        <option value="أكثر من ٩٠٪">أكثر من ٩٠٪</option>
                        <option value="أكثر من ٨٥٪">أكثر من ٨٥٪</option>
                        <option value="أقل من ٨٥٪">أقل من ٨٥٪</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-6  mb-md-3 mb-4">
                    <label htmlFor="how_you_knew" className="mb-2 text-nowrap ">
                      كيف عرفت عن مدرسه ماونتن فيو <br />
                      للدوليه للتكنولوجيا التطبيقيه
                    </label>
                    <div className="d-flex gap-2">
                      <select
                        name="how_you_knew"
                        required
                        value={userData.how_you_knew || ""}
                        onChange={handleChange}
                        className="w-100 px-2"
                        id="how_you_knew"
                      >
                        <option value=""> --اختر-- </option>
                        <option value="السوشيال ميديا">السوشيال ميديا</option>
                        <option value="البروشور">البروشور</option>
                        <option value=" قريب او صديق"> قريب او صديق</option>
                        <option value=" اخري"> اخري</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 mb-md-3 mb-4 ">
                    <label htmlFor="gender" className="mb-2">
                      {" "}
                      {t("signUpGender")}{" "}
                    </label>
                    <div className="d-flex gap-2">
                      <select
                        required
                        name="gender"
                        value={userData.gender || ""}
                        onChange={handleChange}
                        className="w-100 px-2"
                        id="gender"
                      >
                        <option>{t("signUpSelectGender")}</option>
                        <option value="male">{t("signUpMale")}</option>
                        <option value="female">{t("signUpFemale")}</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 mb-md-3 mb-4 ">
                    <label htmlFor="governorate" className="mb-2">
                      {" "}
                      {t("signUpGovernorate")}{" "}
                    </label>
                    <div className="d-flex gap-2">
                      <select
                        value={userData.governate || ""}
                        required
                        onChange={handleChange}
                        name="governate"
                        className="w-100 px-2"
                        id="governorate"
                      >
                        <option>{t("signUpSelectGovernorate")}</option>
                        {selectOptionList}
                      </select>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 mb-md-3 mb-4 ">
                    <label htmlFor="schoolName" className="form-label">
                      {" "}
                      اسم المدرسه الملتحق بها حاليا
                    </label>
                    <input
                      type="text"
                      required
                      name="school_name"
                      value={userData.school_name || ""}
                      onChange={handleChange}
                      className="form-control"
                      id="schoolName"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 mb-md-3 mb-4 d-flex flex-md-row flex-column gap-3">
                    <button
                      type="submit"
                      className="btn-default border-0 px-4 py-2"
                    >
                      {" "}
                      {t("signUpRegisterNow")}{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Signup;
