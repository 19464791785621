import progImg2 from "../Home/cardsImgs/home-schools.c2.png";
import { useTranslation } from "react-i18next";
import Banner from "./BannerProg2.d.png";
import MinistryLogo from "../../assets/logos/مدارس التكنولوجيا التطبيقية - 2.png";
import USAIDImg from "../../assets/logos/USAID Logo AR.png";
// import { Link } from "react-router-dom";
import pdfFile from "./Foundation Brochure-Arabic_3 digital.pdf";
const Program1 = () => {
  const { t } = useTranslation();

  return (
    <div className="mb-md-5">
      <div className="container-fluid mb-md-5">
        <div className="row">
          <div className="col-12 p-0 position-relative">
            <img
              src={Banner}
              className="img-fluid banner-about-and-contact"
              alt=""
            />
            {localStorage.getItem("i18nextLng") === "ar" ? (
              <h2 className="img-overlay-prog1-ar">
                {t("homeFoundationPrograms")}
              </h2>
            ) : (
              <h2 className="img-overlay-prog1">
                {t("homeFoundationPrograms")}
              </h2>
            )}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className="container mb-md-5 ">
        <div className="row d-flex justify-content-center">
          <div className="col-md-8 col-10">
            <a
              href={pdfFile}
              target="_blank"
              className=" text-decoration-none text-white  p-3 py-4"
              style={{ background: "#b34c07" }}
            >
              لمزيد من المعلومات حمل كتيب المدرسة
            </a>
            <br />
            <br />
            <br />

            <div className="row mb-md-5">
              <h2 className="defaultColor fw-bold">{t("homeVocational")}</h2>
            </div>
            <div className="row mb-5">
              <div className="order-1 order-md-0 col-md-8 col-12">
                <p className="defaultColor fs-5">
                  {t("progConditionsVocationalDesc")}
                </p>
                <br />
                <h5 className="fw-bold defaultColor">
                  {t("progConditionsVocational")}
                </h5>
                <ul className="mb-4">
                  <li>{t("prog2Con1")}</li>
                  <li>{t("prog2Con2")}</li>
                  <li>{t("prog2Con3")}</li>
                  <li>{t("prog2Con4")}</li>
                  <li>{t("prog2Con5")}</li>
                  <li>{t("prog2Con6")}</li>
                  {/* <li>{t("prog2Con7")}</li>
                  <li>{t("prog2Con8")}</li> */}
                </ul>

                {/* <a
                  href="/beta/#/register"
                  className="btn-default text-decoration-none border-0 d-inline-block w-auto  py-2 px-4 fw-bold"
                >
                  {t("aboutRegisterNow")}{" "}
                  {localStorage.getItem("i18nextLng") === "en" ? (
                    <span className="rightArrow">&rarr;</span>
                  ) : (
                    <span className="rightArrow">&larr;</span>
                  )}
                </a> */}
              </div>
              <div className="mb-4 col-md-4 col-12">
                <img src={progImg2} alt="" className="img-fluid rounded-5 " />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-md-5 ">
        <div className="row d-flex justify-content-center">
          <div className="col-md-8 col-10">
            {/* <div className="row mb-md-4">
              <h2 className="defaultColor fw-bold">{t("homeVocational")}</h2>
            </div> */}
            <div className="row mb-5">
              <div className="order-1 order-md-0 col-md-8 col-12">
                {/* <p className="defaultColor">{t("progConditionsVocational")}</p> */}

                <h5 className="fw-bold defaultColor">
                  {t("progAdvantagesVocational")}
                </h5>
                <ul className="mb-5">
                  <li>{t("prog2Advantages1")}</li>
                  <li>{t("prog2Advantages2")}</li>
                  <li>{t("prog2Advantages3")}</li>
                  <li>{t("prog2Advantages4")}</li>
                  <li>{t("prog2Advantages5")}</li>
                  <li>{t("prog2Advantages6")}</li>
                  <li>{t("prog2Advantages7")}</li>
                  <li>{t("prog2Advantages8")}</li>
                  <li>{t("prog2Advantages9")}</li>
                  {/* <li>{t("prog2Con7")}</li>
                  <li>{t("prog2Con8")}</li> */}
                </ul>
                <h5 className="fw-bold defaultColor">
                  {t("progMajorsVocational")}
                </h5>
                <ul className="mb-4">
                  <li>{t("prog2Majors1")}</li>
                  <li>{t("prog2Majors2")}</li>
                </ul>

                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://goo.gl/maps/4EBZWQTwhb3VG4JeA"
                  className="defaultColor"
                >
                  قطعة ١ منطقة ٢٦٠٠ فدان حي الحرية العبور الجديدة.
                </a>
                <br />
                <br />
                <a
                  href="#/register"
                  className="btn-default text-decoration-none border-0 d-inline-block w-auto  py-2 px-4 fw-bold"
                >
                  {t("aboutRegisterNow")}{" "}
                  {localStorage.getItem("i18nextLng") === "en" ? (
                    <span className="rightArrow">&rarr;</span>
                  ) : (
                    <span className="rightArrow">&larr;</span>
                  )}
                </a>
              </div>
            </div>
            <div className="row">
              <center className="d-flex align-items-center justify-content-center ">
                <img
                  src={MinistryLogo}
                  className="img-fluid h-75 "
                  alt="Ministry Logo"
                />
                <img
                  src={USAIDImg}
                  className="img-fluid h-75 "
                  alt="Ministry Logo"
                />
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Program1;
