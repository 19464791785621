import React from "react";
import Slider from "react-slick";
import Banner from "./sliderImgs/Banner.e.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Slides() {
  const { t } = useTranslation();

  const images = [
    {
      url: Banner,
      title: t("homeMainTitle"),
      description: t("homeAboutFoundationDesc"),
    },
    {
      url: Banner,
      title: t("homeMainTitle"),
      description: t("homeAboutFoundationDesc"),
    },
    {
      url: Banner,
      title: t("homeMainTitle"),
      description: t("homeAboutFoundationDesc"),
    },
    {
      url: Banner,
      title: t("homeMainTitle"),
      description: t("homeAboutFoundationDesc"),
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    cssEase: "linear",
    pauseOnHover: true,
    pauseOnFocus: true,
    pauseOnDragging: true,
    swipe: false,

    // customPaging: (i) => (

    // ),
  };
  return (
    <div className="container-fluid">
      <div className="row px-0">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div
              key={index}
              className="col-12 col-md-4 mb-4 position-relative mySlides"
            >
              <div className="">
                <img src={image.url} className="img-fl slider-img" alt="" />
              </div>

              <div
                className={` ${
                  localStorage.getItem("i18nextLng") === "en"
                    ? "slider-overlay2 text-start"
                    : "slider-overlay2-ar text-end"
                }`}
              >
                <h1 className="slide-title mb-md-3 mb-1">{image.title}</h1>
                <p className="slide-desc mb-md-5 mb-3">{image.description}</p>

                <Link
                  to="/about"
                  className="btn-default text-decoration-none border-0 p-3 rounded-pill"
                >
                  {localStorage.getItem("i18nextLng") === "ar" ? (
                    <>
                      <span className="leftArrow">&larr;</span>{" "}
                      {t("homeExploremore")}
                    </>
                  ) : (
                    <>
                      {t("homeExploremore")}{" "}
                      <span className="rightArrow">&rarr;</span>
                    </>
                  )}
                </Link>
                <br />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
