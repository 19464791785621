import mvLogo from "../../assets/images/mvLogo.png";
import arLogo from "../../assets/images/arabicLogo.png";
import { useTranslation } from "react-i18next";
// import { useContext } from "react";
import { NavLink } from "react-router-dom";
// import { customerContext } from "../../axios";

const Navbar = () => {
  // const { changeLanguage } = useContext(customerContext);

  const { t } = useTranslation();

  return (
    <div className="container justify-content-center ">
      <div className="row  justify-content-center align-items-center">
        <div className="col-8">
          <nav className="navbar navbar-expand-lg">
            <div className="d-flex justify-content-center align-items-center">
              <NavLink
                className={`navbar-brand  ${
                  localStorage.getItem("i18nextLng") === "en" ? "me-3" : "ms-3"
                }`}
                to="/"
              >
                {localStorage.getItem("i18nextLng") === "ar" ? (
                  <img src={arLogo} alt="Mountain View Logo" width={155} />
                ) : (
                  <img src={mvLogo} alt="Mountain View Logo" width={250} />
                )}
              </NavLink>
              <button
                className=" navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div
              className="collapse navbar-collapse justify-content-center"
              id="navbarSupportedContent"
            >
              <ul className={`navbar-nav mb-2 mb-lg-0 align-items-center`}>
                <li className="nav-item mx-3">
                  <NavLink className="nav-link" to="/">
                    {t("navHome")}
                  </NavLink>
                </li>
                <li className="nav-item mx-3">
                  <NavLink className="nav-link" to="/about">
                    {t("navAbout")}
                  </NavLink>
                </li>
                <li className="nav-item mx-3">
                  <NavLink className="nav-link " to="/programs">
                    {t("navPrograms")}
                  </NavLink>
                </li>
                {/* <li className="nav-item mx-1">
                    <NavLink className="nav-link " to="javascript:;">{t("navPartnerships")}</NavLink>
                  </li>
                  <li className="nav-item mx-1">
                    <NavLink className="nav-link " to="javascript:;">{t("navSectors")}</NavLink>
                  </li>
                  <li className="nav-item mx-1">
                    <NavLink className="nav-link " to="/careers">{t("navCareers")}</NavLink>
                  </li> */}
                <li className="nav-item mx-3">
                  <NavLink className="nav-link " to="/contact">
                    {t("navContact")}
                  </NavLink>
                </li>
                <li className="nav-item mx-3">
                  <i className="bi bi-search"></i>
                </li>
                {/* <li className="nav-item mx-3">
                  {localStorage.getItem("i18nextLng") === "ar" ? (
                    <button
                      onClick={() => changeLanguage("en")}
                      className="btn btn-default mx-3 px-3"
                    >
                      English
                    </button>
                  ) : (
                    <button
                      onClick={() => changeLanguage("ar")}
                      className="btn btn-default mx-3 px-3"
                    >
                      عربى
                    </button>
                  )}
                </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
