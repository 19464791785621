import React from "react";
import ReactDOM from "react-dom/client";
import "./fonts/Poppins-Regular.ttf";
import "./fonts/Tajawal-Regular.ttf";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18n from "./i18next-icu/i18n";
import { I18nextProvider } from "react-i18next";
import { CustomerProvider } from "./axios";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CustomerProvider>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </CustomerProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
