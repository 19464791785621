import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="mb-md-5">
      <div className="container-fluid mb-md-5">
        <div className="row">
          <div className="col-12 p-0 position-relative">
            {/* <img src={Banner} className="img-fluid" alt="" /> */}
            <div className="">
              <h2 className="img-overlay">{t("footerPrivacyPolicy")}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-md-5 ">
        <div className="row d-flex justify-content-center">
          <div className="col-md-8 col-10 ">
            <div className="row mb-md-5 mb-3">
              <h2 className="defaultColor fw-bold">{t("privacyTitle")}</h2>
            </div>
            <div className="row mb-md-5 mb-3">
              <h5 className="fw-bold defaultColor">{t("privacySubTitle")}</h5>
              <p>{t("homeAboutFoundationDesc")}</p>
            </div>
            <div className="row mb-md-5 mb-3">
              <h5 className="fw-bold defaultColor">{t("privacySubTitle")}</h5>
              <p>{t("homeAboutFoundationDesc")}</p>
            </div>
            <div className="row mb-md-5 mb-3">
              <h5 className="fw-bold defaultColor">{t("privacySubTitle")}</h5>
              <p>{t("homeAboutFoundationDesc")}</p>
            </div>
            <div className="row mb-md-5 mb-3">
              <h5 className="fw-bold defaultColor">{t("privacySubTitle")}</h5>
              <p>{t("homeAboutFoundationDesc")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
