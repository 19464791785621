import { useTranslation } from "react-i18next";
import AboutContent from "./AboutContentLatest.png";
import Banner from "./Banner-About.png";

const About = () => {
  const { t } = useTranslation();

  return (
    <div className="mb-md-5">
      <div className="container-fluid mb-md-5">
        <div className="mb-5 row">
          <div className=" col-12 p-0 position-relative">
            <img src={Banner} className="banner-about-and-contact" alt="" />
            {localStorage.getItem("i18nextLng") === "en" ? (
              <h2 className="img-overlay">{t("aboutTheFoundation")}</h2>
            ) : (
              <h2 className="img-overlay-about-ar">
                {t("aboutTheFoundation")}
              </h2>
            )}
          </div>
        </div>
      </div>
      <br />
      <br />

      <div className="container  my-md-5 ">
        <div className="row d-flex mt-5 justify-content-center">
          <div className="col-md-8 col-10 ">
            <div className="row mb-md-5 mb-3">
              <h2 className="defaultColor fw-bold">{t("aboutMVF")}</h2>
            </div>
            <div className="row mb-md-5 mb-3">
              <div className="col-md-8 col-12">
                <p>{t("aboutMVDesc")}</p>
                <p>
                  <span className="defaultColor fw-bold ">
                    {t("aboutFirstProgram")}
                  </span>{" "}
                  {t("aboutFirstProgramInfo")}
                </p>
                <p>
                  <span className="defaultColor fw-bold">
                    {t("aboutSecondProgram")}
                  </span>{" "}
                  {t("aboutSecondProgramInfo")}
                </p>
                <p>
                  <span className="defaultColor fw-bold">
                    {t("aboutThirdProgram")}
                  </span>{" "}
                  {t("aboutThirdProgramInfo")}
                </p>
                <p>
                  <span className="defaultColor fw-bold">
                    {t("aboutFourthProgram")}
                  </span>{" "}
                  {t("aboutFourthProgramInfo")}
                </p>
              </div>
              <div className="col-md-4 col-12">
                <img
                  src={AboutContent}
                  className="img-fluid rounded-5 "
                  alt="Many hands holding oregami papers"
                />
              </div>
            </div>
            {/* <div className="row mb-md-5 mb-3">
              <div className="col-md-4 col-12 d-flex gap-3">
                <span>
                  <img src={no1} width="50" alt="" />
                </span>{" "}
                <p>{t("aboutStepOne")}</p>
              </div>
              <div className="col-md-4 col-12 d-flex gap-3">
                <span>
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      opacity="0.3"
                      cx="25"
                      cy="25"
                      r="25"
                      transform="matrix(-1 0 0 1 50 0)"
                      fill="#66C2B3"
                    />
                    <circle
                      cx="17"
                      cy="17"
                      r="17"
                      transform="matrix(-1 0 0 1 42 8)"
                      fill="#66C2B3"
                    />
                    <path
                      d="M19.8477 33V29.906L23.8517 25.2C25.0304 23.8307 25.7497 22.9293 26.0097 22.496C26.2697 22.0627 26.3997 21.5513 26.3997 20.962C26.3997 20.4767 26.2264 20.078 25.8797 19.766C25.5331 19.454 25.0997 19.298 24.5797 19.298C23.5571 19.298 22.4217 19.7227 21.1737 20.572L19.6397 18.336C21.4251 16.9147 23.2971 16.204 25.2557 16.204C26.7637 16.204 27.9597 16.6027 28.8437 17.4C29.7277 18.18 30.1697 19.2893 30.1697 20.728C30.1697 22.2187 29.5111 23.8567 28.1937 25.642C27.6911 26.318 26.3997 27.8 24.3197 30.088H30.0397V33H19.8477Z"
                      fill="white"
                    />
                  </svg>
                </span>{" "}
                <p>{t("aboutStepTwo")}</p>
              </div>
              <div className="col-md-4 col-12 d-flex gap-3">
                <span>
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      opacity="0.3"
                      cx="25"
                      cy="25"
                      r="25"
                      transform="matrix(-1 0 0 1 50 0)"
                      fill="#66C2B3"
                    />
                    <circle
                      cx="17"
                      cy="17"
                      r="17"
                      transform="matrix(-1 0 0 1 42 8)"
                      fill="#66C2B3"
                    />
                    <path
                      d="M21.5704 25.746V22.782H24.1184H24.3264C24.9331 22.782 25.3491 22.6607 25.5744 22.418C25.8171 22.1753 25.9384 21.7333 25.9384 21.092C25.9384 20.5373 25.7824 20.0953 25.4704 19.766C25.1584 19.4367 24.7424 19.272 24.2224 19.272C23.1478 19.272 22.0471 19.7747 20.9204 20.78L19.5944 18.414C21.0504 16.958 22.7058 16.23 24.5604 16.23C26.0858 16.23 27.3424 16.6633 28.3304 17.53C29.2838 18.3447 29.7604 19.428 29.7604 20.78C29.7604 21.6467 29.6218 22.314 29.3444 22.782C29.0671 23.25 28.5731 23.6487 27.8624 23.978C29.5264 24.6713 30.3584 25.954 30.3584 27.826C30.3584 29.906 29.6564 31.4053 28.2524 32.324C27.2818 32.9653 26.0598 33.286 24.5864 33.286C23.7891 33.286 22.8618 33.0607 21.8044 32.61C20.7471 32.1593 19.9064 31.6307 19.2824 31.024L20.7644 28.814C21.6658 29.6807 22.8618 30.114 24.3524 30.114C25.0284 30.114 25.5658 29.9147 25.9644 29.516C26.3631 29.1173 26.5624 28.58 26.5624 27.904C26.5624 26.4653 25.7478 25.746 24.1184 25.746H21.5704Z"
                      fill="white"
                    />
                  </svg>
                </span>{" "}
                <p>{t("aboutStepThree")}</p>
              </div>
            </div> */}
            {/* <div className=" mb-md-5 mb-3 d-flex justify-content-center">
              <Link
                to="/register"
                className="btn-default text-decoration-none border-0 px-4 py-2 rounded-pill"
              >
                {t("aboutRegisterNow")}{" "}
                {localStorage.getItem("i18nextLng") === "en" ? (
                  <span className="rightArrow">&rarr;</span>
                ) : (
                  <span className="rightArrow">&larr;</span>
                )}
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
