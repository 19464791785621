import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

const customerContext = React.createContext();

const CustomerProvider = ({ children }) => {
  const [langType, setLangType] = useState(
    localStorage.getItem("i18nextLng") || "en"
  );
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (langType === "ar") {
      document.getElementsByTagName("Body")[0].style.direction = "rtl";
    } else {
      document.getElementsByTagName("Body")[0].style.direction = "rtl";
      changeLanguage("ar");
    }
  }, [langType]);

  const changeLanguage = (lng) => {
    // localStorage.setItem("lng", lng);
    // toggleDirection();
    // console.log(lng);
    setLangType(lng);
    i18n.changeLanguage(lng);
    // document.location.reload();
  };

  const baseAPI = "https://mountainview.foundation/_mvf_api/";

  const setRegisterData = async (formData, data_type = "") => {
    console.log(formData, data_type);
    try {
      const data = await axios.post(
        baseAPI,
        { data_type: `program${data_type}_register`, request_data: formData },
        { headers: { "Content-Type": "application/json" } }
      );

      console.log("RESPONSE", data);
      if (data.data.response === "SUCCESS") setSubmittedSuccessfully(true);
      else setSubmittedSuccessfully(false);
      console.log(submittedSuccessfully, data.data.statusText);
      return data.data;
    } catch (error) {
      console.log(error);
    }
  };

  function test() {
    console.log("TESTING ...");
  }
  return (
    <customerContext.Provider
      value={{
        setRegisterData,
        changeLanguage,
        submittedSuccessfully,
        test,
      }}
    >
      {children}
    </customerContext.Provider>
  );
};

export { customerContext, CustomerProvider };
