import ci1 from "./cardsImgs/card-imgOne.png";
import ci2 from "./cardsImgs/home-schools.c2.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Cards = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="row  p-md-2 p-0 mb-5 d-flex justify-content-center">
        <div className="col-12 col-md-12 col-lg-4 p-md-3 p-3">
          <div className="card h-100 border-0">
            <img
              src={ci1}
              className="card-img-top rounded-5"
              width={20}
              alt="..."
            />
            <div className={`card-body px-0`}>
              <h3 className="card-title defaultColor">
                {" "}
                {t("homeDiscoveringHeroes")}
              </h3>
              <p className="card-text">{t("progsDiscoverHeroes")}</p>
            </div>
            <div className="card-footer px-0 bg-white border-0 d-flex gap-3">
              <Link
                to="/program1"
                className="btn-default border-0 text-decoration-none d-inline-block w-auto  py-2 px-5 fw-bold"
              >
                {t("homeReadMore")}{" "}
                {localStorage.getItem("i18nextLng") === "en" ? (
                  <span className="rightArrow">&rarr;</span>
                ) : (
                  <span className="rightArrow">&larr;</span>
                )}
              </Link>
              <Link
                to=""
                className="btn-default border-0 text-decoration-none d-inline-block w-auto  py-2 px-4 fw-bold text-danger-subtle"
              >
                 انتهى التسجيل
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-4 p-md-3 p-3">
          <div className="card h-100 border-0">
            <img
              src={ci2}
              className="card-img-top rounded-5 "
              width={20}
              alt="..."
            />
            <div className={`card-body px-0 `}>
              <h3 className="card-title defaultColor">{t("homeVocational")}</h3>
              <p className="card-text">{t("homeSecProgMainTitleDesc")}</p>
            </div>
            <div className="card-footer px-0 bg-white border-0 d-flex gap-3">
              <Link
                to="/program2"
                className="btn-default border-0 text-decoration-none d-inline-block w-auto  py-2 px-5 fw-bold"
              >
                {t("homeReadMore")}{" "}
                {localStorage.getItem("i18nextLng") === "en" ? (
                  <span className="rightArrow">&rarr;</span>
                ) : (
                  <span className="rightArrow">&larr;</span>
                )}
              </Link>
              <Link
                to="/register"
                className="btn-default border-0 text-decoration-none d-inline-block w-auto  py-2 px-5 fw-bold"
              >
                سجل الآن
                {localStorage.getItem("i18nextLng") === "en" ? (
                  <span className="rightArrow">&rarr;</span>
                ) : (
                  <span className="rightArrow">&larr;</span>
                )}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cards;
