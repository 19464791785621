import "./App.css";

import { HashRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Navbar from "./pages/Home/Navbar";
import Signup from "./pages/Signup/Signup";
import SignupProg1 from "./pages/Signup/SignupProg1";
import Footer from "./components/Footer";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Careers from "./pages/Careers/Careers";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Programs from "./pages/Programs/Programs";
import Program1 from "./pages/Programs/Program1";
import Program2 from "./pages/Programs/Program2";

function App() {
  return (
    <div className="overflow-hidden" id="rootApp">
      <HashRouter basename="/">
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/register" exact element={<Signup />} />
          <Route path="/register-prog-1" exact element={<SignupProg1 />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="/careers" exact element={<Careers />} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
          <Route path="/programs" exact element={<Programs />} />
          <Route path="/program1" exact element={<Program1 />} />
          <Route path="/program2" exact element={<Program2 />} />
        </Routes>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
