import { useTranslation } from "react-i18next";

const Careers = () => {
  const { t } = useTranslation();

  return (
    <div className="mb-md-5">
      <div className="container-fluid mb-md-5">
        <div className="row">
          <div className="col-12 p-0 position-relative">
            {/* <img src={Banner} className="img-fluid" alt="" /> */}
            {localStorage.getItem("i18nextLng") === "en" ? (
              <h2 className="img-overlay">{t("navCareers")}</h2>
            ) : (
              <h2 className="img-overlay-ar-cont-careers">{t("navCareers")}</h2>
            )}
          </div>
        </div>
      </div>
      <div className="container mb-md-5 ">
        <div className="row d-flex justify-content-center">
          <div className="col-md-8 col-10 ">
            <div className="row mb-md-5 mb-3">
              <h2 className="defaultColor fw-bold">{t("careerTitle")}</h2>
            </div>
            <div className="row mb-md-5 mb-3">
              <h5 className="fw-bold defaultColor">{t("careerJobTitle")}</h5>
              <p>{t("homeAboutFoundationDesc")}</p>

              <button className="btn-default border-0 d-inline-block w-auto  py-2 px-5 fw-bold">
                {t("careerBtn")}{" "}
                {localStorage.getItem("i18nextLng") === "en" ? (
                  <span className="rightArrow">&rarr;</span>
                ) : (
                  <span className="rightArrow">&larr;</span>
                )}
              </button>
            </div>
            <div className="row mb-md-5 mb-3">
              <h5 className="fw-bold defaultColor">{t("careerJobTitle")}</h5>
              <p>{t("homeAboutFoundationDesc")}</p>

              <button className="btn-default border-0 d-inline-block w-auto  py-2 px-5 fw-bold">
                {t("careerBtn")}{" "}
                {localStorage.getItem("i18nextLng") === "en" ? (
                  <span className="rightArrow">&rarr;</span>
                ) : (
                  <span className="rightArrow">&larr;</span>
                )}
              </button>
            </div>
            <div className="row mb-md-5 mb-3">
              <h5 className="fw-bold defaultColor">{t("careerJobTitle")}</h5>
              <p>{t("homeAboutFoundationDesc")}</p>

              <button className="btn-default border-0 d-inline-block w-auto  py-2 px-5 fw-bold">
                {t("careerBtn")}{" "}
                {localStorage.getItem("i18nextLng") === "en" ? (
                  <span className="rightArrow">&rarr;</span>
                ) : (
                  <span className="rightArrow">&larr;</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
