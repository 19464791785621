import img1 from "./progOne.png";
import img2 from "../Home/cardsImgs/home-schools.c2.png";
import Banner from "./Banner-Programs.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Programs = () => {
  const { t } = useTranslation();

  return (
    <div className="mb-md-5">
      <div className="container-fluid mb-md-5">
        <div className="mb-5 row">
          <div className="col-12 p-0 position-relative">
            <img src={Banner} className="banner-about-and-contact" alt="" />
            <div className="">
              {localStorage.getItem("i18nextLng") === "en" ? (
                <h2 className="img-overlay-prog">{t("progImage")}</h2>
              ) : (
                <h2 className="img-overlay-prog-ar">{t("progImage")}</h2>
              )}
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className=" container mb-md-5">
        <div className="row d-flex justify-content-center">
          <div className="col-md-11 col-10">
            <div className="row mb-md-5 mb-3">
              <h2 className="defaultColor fw-bold text-center">
                {t("homeFoundationPrograms")}
              </h2>
            </div>

            <div className="row mb-md-5 mb-3 d-flex justify-content-center align-items-center">
              <div className="mb-md-4 mb-3 col-md-4 order-1 order-md-0 col-12">
                <h5 className="fw-bold defaultColor">{t("giftedSector")}</h5>
                <p>{t("giftedSectorDesc")}</p>

                <Link
                  to="/program1"
                  className="btn-default border-0 text-decoration-none d-inline-block w-auto  py-2 px-5 fw-bold"
                >
                  {t("homeReadMore")}{" "}
                  {localStorage.getItem("i18nextLng") === "en" ? (
                    <span className="rightArrow">&rarr;</span>
                  ) : (
                    <span className="rightArrow">&larr;</span>
                  )}
                </Link>
              </div>
              <div className="mb-md-4 mb-3 col-md-5 col-12 d-flex justify-content-end">
                <img src={img1} className="img-fluid w-100 rounded-5 " alt="" />
              </div>
            </div>

            <div className="row mb-md-5 mb-3 d-flex justify-content-center align-items-center">
              <div className="mb-md-4 mb-3 col-md-5 col-12 d-flex justify-content-start">
                <img src={img2} className="img-fluid w-100 rounded-5 " alt="" />
              </div>
              <div className="mb-md-4 mb-3 col-md-4 col-12">
                <h5 className="fw-bold defaultColor">{t("trainingSector")}</h5>
                <p>{t("trainingSectorDesc")}</p>

                <Link
                  to="/program2"
                  className="btn-default border-0 text-decoration-none d-inline-block w-auto  py-2 px-5 fw-bold"
                >
                  {t("homeReadMore")}{" "}
                  {localStorage.getItem("i18nextLng") === "en" ? (
                    <span className="rightArrow">&rarr;</span>
                  ) : (
                    <span className="rightArrow">&larr;</span>
                  )}
                </Link>
              </div>
            </div>
            {/* <div className="row mb-md-5 mb-3 d-flex justify-content-center align-items-center">
              <div className="col-md-4 col-12">
                <h5 className="fw-bold defaultColor">{t("homeRealEstate")}</h5>
                <p>{t("homeAboutFoundationDesc")}</p>

                <button className="btn-default border-0 d-inline-block w-auto  py-2 px-5 fw-bold">
                  {t("homeReadMore")}{" "}
                  {localStorage.getItem("i18nextLng") === "en" ? (
                    <span className="rightArrow">&rarr;</span>
                  ) : (
                    <span className="rightArrow">&larr;</span>
                  )}
                </button>
              </div>
              <div className="col-md-5 col-12 d-flex justify-content-end">
                <img src={img3} className="img-fluid w-100" alt="" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Programs;
