import Cards from "./Cards";
import "./Home.css";
import Slides from "./Slides";
import logo1 from "../../assets/logos/img1.png";
import logo2 from "../../assets/logos/img2.png";
import logo3 from "../../assets/logos/img3.png";
import logo4 from "../../assets/logos/img4.png";
import logo5 from "../../assets/logos/img5.png";
import logo6 from "../../assets/logos/img6.png";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="container-fluid mb-md-5 mb-2 p-0 overflow-hidden">
        <Slides />
      </div>
      <div className="container">
        <div className="row text-center">
          <h3 className="mainTitle"> {t("homeFoundationPrograms")}</h3>
        </div>
        <Cards />
      </div>
      <div className="container">
        <div className="row footerLogos ">
          <div className="col-12 col-md-4  d-flex justify-content-center align-items-center ">
            <img src={logo1} className="img-fluid  " alt="" />
          </div>
          <div className="col-12 col-md-4 ministryLogoParent d-flex justify-content-center align-items-center ">
            <img src={logo2} width={512} alt="Ministry Logo" />
          </div>
          <div className="col-12 col-md-4  d-flex justify-content-center align-items-center ">
            <img src={logo3} className="img-fluid" alt="USAID logo" />
          </div>
          <center className="d-flex justify-content-center mt-5">
            <div className="col-12 col-md-3 d-flex justify-content-center align-items-center ">
              <img
                src={logo4}
                className="img-fluid "
                alt="Nestle Company Logo"
              />
            </div>
            <div className="col-12 col-md-3 d-flex justify-content-center align-items-center ">
              <img
                src={logo5}
                className="img-fluid"
                alt="Wellspring Foundation Logo"
              />
            </div>
            <div className="col-12 col-md-3 d-flex justify-content-center align-items-center ">
              <img src={logo6} className="img-fluid" alt="Takamol Logo" />
            </div>
          </center>
        </div>
      </div>
    </div>
  );
};

export default Home;
