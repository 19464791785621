import mvLogo from "../assets/images/Logo (1).png";
import arLogo from "../assets/images/arabicLogo.png";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";

const Footer = () => {
  const { t } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="shadow py-5 pb-4">
      <div className="container shado">
        <div className="row d-flex justify-content-center my-5">
          <div className="col-md-9 col-10">
            <div className="row gx-md-5 gx-2">
              <div className="col-12 col-md-5">
                <div className="col-12 mb-md-4 mb-2">
                  {localStorage.getItem("i18nextLng") === "en" ? (
                    <Link
                      to="./"
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      <img
                        src={mvLogo}
                        className="img-fluid"
                        alt="Mountain View Logo"
                      />
                    </Link>
                  ) : (
                    <Link
                      to="./"
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      <img
                        src={arLogo}
                        className="img-fluid"
                        alt="Mountain View Logo"
                      />
                    </Link>
                  )}
                </div>
                {/* <p>{t("footerInfo")}</p> */}
              </div>
              <div className="col-12 col-md-5">
                <div className="row mb-3">
                  <div className="col-6">
                    <Link
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                      to="./"
                      className="text-decoration-none text-dark"
                    >
                      <h4>{t("navHome")}</h4>
                    </Link>
                  </div>
                  <div className="col-6">
                    <Link
                      to="./about"
                      className="text-decoration-none text-dark"
                    >
                      <h4>{t("navAbout")}</h4>
                    </Link>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-6">
                    <Link
                      to="./contact"
                      className="text-decoration-none text-dark"
                    >
                      <h4>{t("navContact")}</h4>
                    </Link>
                  </div>
                  <div className="col-6">
                    <Link
                      to="./programs"
                      className="text-decoration-none text-dark"
                    >
                      <h4>{t("navPrograms")}</h4>
                    </Link>
                  </div>
                </div>
                {/* <div className="row mb-3">
                  <h6 className="col-6">{t("navContact")}</h6>
                  <h6 className="col-6">{t("navCareers")}</h6>
                </div>
                <div className="row mb-3">
                  <h6 className="col-6">{t("footerPrivacyPolicy")}</h6>
                  <h6 className="col-6">{t("footerJoinUs")}</h6>
                </div> */}
              </div>
              <div className="col-12 col-md-2">
                <div className="">
                  <div className="d-flex justify-content-center gap-5">
                    <a
                      className=""
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/FoundationMV"
                    >
                      <i className="bi-facebook  fs-1"></i>
                    </a>
                    <a href="" target="_blank" rel="noreferrer">
                      <i className="bi-linkedin  fs-1"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="m-0 mb-3" />
      <center className="p-2 ">
        <small className="m-0">{t("footerCopyrights")}</small>
      </center>
    </div>
  );
};

export default Footer;
