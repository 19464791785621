import proben1 from "./probenOneLatest.png";
import proben2 from "./probenTwoLatest.png";
import proben3 from "./probenThreeLatest.png";
import proben4 from "./probenFourLatest.png";
import logo1 from "../../assets/logos/ministry.png";
import logo2 from "../../assets/logos/tadamon.jpg";
import logo3 from "../../assets/logos/WS Foundation Logo-01.png";
import logo4 from "../../assets/logos/NPL logo-min (1).png";
import progImg from "./prog1-intro.png";
import { useTranslation } from "react-i18next";
import Banner from "../Programs/Banner-Programs.png";
import { Link } from "react-router-dom";

const Program1 = () => {
  const { t } = useTranslation();

  return (
    <div className="mb-md-5">
      <div className="container-fluid mb-md-5">
        <div className="row">
          <div className="col-12 p-0 position-relative">
            <img src={Banner} className="banner-about-and-contact" alt="" />
            {localStorage.getItem("i18nextLng") === "en" ? (
              <h2 className="img-overlay-prog1">
                {t("homeFoundationPrograms")}
              </h2>
            ) : (
              <h2 className="img-overlay-prog1-ar">
                {t("homeFoundationPrograms")}
              </h2>
            )}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className="container mb-md-5 ">
        <div className="row d-flex justify-content-center">
          <div className="col-md-9 col-10">
            <div className="row mb-md-3">
              <h2 className="defaultColor fw-bold">
                {t("progFoundationProgramsTitle")}
              </h2>
            </div>
            <div className="row d-flex mb-5">
              <div className="order-1 order-md-0 col-md-8 mb-5 col-12">
                <p className="defaultColor fs-5">
                  {/* {t("progConditionsDesc")} */}
                  يهدف برنامج «اكتشاف الأبطال» إلى البحث عن مواهب اليوم وأبطال
                  الغد بالمدارس الحكومية في قرى محافظات مصر المختلفة
                </p>
                <h5 className="fw-bold defaultColor">{t("progConditions")}</h5>
                <ul className="mb-4">
                  <li>{t("progCon1")}</li>
                  {/* <li>{t("progCon2")}</li> */}
                  <li>
                    ب- التميز: أن يكون لدي المرشح موهبة في المجالات الآتية:
                  </li>
                  <li className="px-4 list-unstyled"> * الفنون الأدائية</li>
                  <li className="px-4 list-unstyled"> * الفنون الجميلة</li>
                  <li className="px-4 list-unstyled"> * الرياضة</li>
                  <li className="px-4 list-unstyled"> * العلوم والتكنولوچيا</li>
                  <li>{t("progCon3")}</li>
                  <li>{t("progCon4")}</li>
                  <li>{t("progCon5")}</li>
                  <li>{t("progCon6")}</li>
                  {/* <li>{t("progCon7")}</li>
                  <li>{t("progCon8")}</li> */}
                  <li className="px-4 list-unstyled">
                    &#x2022;{t("progCon9")}
                  </li>
                  <li className="px-4 list-unstyled ">
                    &#x2022;{t("progCon10")}
                  </li>
                </ul>

                {/* <Link
                  to="/register-prog-1"
                  className="btn-default text-decoration-none border-0 d-inline-block w-auto  py-2 px-4 fw-bold"
                >
                  {t("aboutRegisterNow")}{" "}
                  {localStorage.getItem("i18nextLng") === "en" ? (
                    <span className="rightArrow">&rarr;</span>
                  ) : (
                    <span className="rightArrow">&larr;</span>
                  )}
                </Link> */}
              </div>
              <div className="mb-4 col-md-4 col-12">
                <img src={progImg} alt="" className="img-fluid rounded-5 " />
              </div>
              {/* <center>
                <img src={ministryImg} className="img-fluid" alt="" />
              </center> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container mb-md-3 ">
        <div className="row d-flex justify-content-center">
          <div className="col-md-9 col-10">
            <div className="row mb-md-5">
              <h4 className="defaultColor fw-bold text-center">
                {t("progBenefits")}
              </h4>
            </div>
            <div className="row mb-5 g-5">
              <div className="col-md-6 col-12">
                <img
                  src={proben1}
                  className="img-fluid mb-4 rounded-5"
                  alt=""
                />
                <h6 className="defaultColor fw-bold ">{t("progExpPhase")}</h6>
                <ul>
                  <li>{t("progPhase1")}</li>
                  {/* <li>{t("progPhase2")}</li> */}
                </ul>
              </div>
              <div className="col-md-6 col-12">
                <img
                  src={proben2}
                  className="img-fluid mb-4 rounded-5"
                  alt=""
                />
                <h6 className="defaultColor fw-bold">{t("progDevPhase")}</h6>
                <ul>
                  <li>{t("progPhase3")}</li>
                  {/* <li>{t("progPhase4")}</li>
                  <li>{t("progPhase5")}</li> */}
                </ul>
              </div>
            </div>
            <div className="row mb-5 g-5">
              <div className="col-md-6 col-12">
                <img
                  src={proben4}
                  className="img-fluid mb-4 rounded-5"
                  alt=""
                />
                <h6 className="defaultColor fw-bold">{t("progFinalPhase")}</h6>
                <ul>
                  <li>{t("progPhase9")}</li>
                  {/* <li>{t("progPhase10")}</li> */}
                </ul>
              </div>
              <div className="col-md-6 col-12">
                <img
                  src={proben3}
                  className="img-fluid mb-4 rounded-5"
                  alt=""
                />
                <h6 className="defaultColor fw-bold">
                  {t("progAdoptionPhase")}
                </h6>
                <ul>
                  <li>{t("progPhase6")}</li>
                  {/* <li>{t("progPhase7")}</li>
                  <li>{t("progPhase8")}</li> */}
                </ul>
              </div>
            </div>
            <div className="mb-md-5 mb-3 d-flex justify-content-center">
              {/* <Link
                to="/register-prog-1"
                className="btn-default text-decoration-none border-0 text-center px-3 py-2 rounded-pill"
              >
                {t("aboutRegisterNow")}{" "}
                {localStorage.getItem("i18nextLng") === "en" ? (
                  <span className="rightArrow">&rarr;</span>
                ) : (
                  <span className="rightArrow">&larr;</span>
                )}
              </Link> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row p-md-2 p-0 mb-3 ">
          <div className="col-12 col-md-3 p-md-3 p-3 d-flex justify-content-center align-items-center ">
            <img src={logo1} className="img-fluid  h-50" alt="" />
          </div>
          <div className="col-12 col-md-3 p-md-3 p-3 d-flex justify-content-center align-items-center ">
            <img src={logo2} className="img-fluid h-50 " alt="" />
          </div>
          <div className="col-12 col-md-3 p-md-3 p-3 d-flex justify-content-center align-items-center ">
            <img src={logo3} className="img-fluid h-50 " alt="" />
          </div>
          <div className="col-12 col-md-3 p-md-3 p-3 d-flex justify-content-center align-items-center ">
            <img src={logo4} className="img-fluid h-50 " alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Program1;
