import React, { useState, useContext } from "react";

import no1 from "./signupImgs/Number-1.png";
import no2 from "./signupImgs/Numbers-2.png";
import no3 from "./signupImgs/Number-3.png";
// import { customerContext } from "../../axios";
import SignUpSuccess from "./SignUpSuccess";
import { useTranslation } from "react-i18next";
import Banner from "./signupImgs/Banner-Register_20(1).802d40c76cb53907c4ea.png";
import { customerContext } from "../../axios";

const Signup = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showSpecify, setShowSpecify] = useState(false);
  const [showArt, setShowArt] = useState(false);
  const [showTech, setShowTech] = useState(false);
  const [showAswan, setShowAswan] = useState(false);
  const [showLuxor, setShowLuxor] = useState(false);
  const [showSohag, setShowSohag] = useState(false);
  const [showKena, setShowKena] = useState(false);
  const [showCertMedal, setShowCertMedal] = useState(false);
  const [firstSelectValue, setFirstSelectValue] = useState("");
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);
  const { t } = useTranslation();

  const { setRegisterData, submittedSuccessfully } =
    useContext(customerContext);

  const [userData, setuserData] = useState({
    governate: "",
    station: "",
    village: "",
    student_name: "",
    age: "",
    gender: "",
    talent: "",
    bullying: "",
    support: "",
    ed_stage: "",
    talent_type: "",
    practice_a_week: "",
    wrestling: false,
    judo: false,
    weightlifting: false,
    handball: false,
    football: false,
    participate_times: "",
    school_support: "",
    tech_science: "",
    tech_math: "",
    tech_english: "",
    communicate: "",
    birth_date: "",
    national_id: "",
    school_name: "",
    phone_number: "",
    parent_phone: "",
    competition_participate: 0,
    video_file: null,
  });
  const [isMobileNumberValid, setIsMobileNumberValid] = useState(true);
  const [isParentNumberValid, setIsParentNumberValid] = useState(true);
  const [isNationalIDValid, setIsNationalIDValid] = useState(true);
  const [isBirthdateValid, setIsBirthdateValid] = useState(true);

  function handleChange(event) {
    const selectedValue = event.target.value;

    setFirstSelectValue(selectedValue);

    const { name, value, type, checked, files } = event.target;

    if (value === "الرياضة" && name === "talent") {
      setShowSpecify(true);
      setShowArt(false);
      setShowTech(false);
    }
    if (value === "الفن" && name === "talent") {
      setShowArt(true);
      setShowTech(false);
      setShowSpecify(false);
    }
    if (value === "التكنولوجيا" && name === "talent") {
      setShowTech(true);
      setShowArt(false);
      setShowSpecify(false);
    }
    if (value === "سوهاج" && name === "governate") {
      setShowSohag(true);
      setShowAswan(false);
      setShowLuxor(false);
      setShowKena(false);
    }
    if (value === "أسوان" && name === "governate") {
      setShowAswan(true);
      setShowSohag(false);
      setShowLuxor(false);
      setShowKena(false);
    }
    if (value === "الأقصر" && name === "governate") {
      setShowLuxor(true);
      setShowAswan(false);
      setShowSohag(false);
      setShowKena(false);
    }
    if (value === "قنا" && name === "governate") {
      setShowKena(true);
      setShowLuxor(false);
      setShowAswan(false);
      setShowSohag(false);
    }

    if (type === "checkbox" && checked) {
      const selectedCheckboxCount = Object.values(userData).filter(
        (val) => val === true
      ).length;
      if (selectedCheckboxCount >= 3) {
        return;
      }
    }
    if (type === "file" && files.length > 0) {
      console.log(files);
      setuserData((prevFormData) => ({
        ...prevFormData,
        [name]: files[0],
      }));
    } else {
      setuserData((prevFormData) => {
        return {
          ...prevFormData,
          [name]: type === "checkbox" ? checked : value,
        };
      });
    }
  }

  const shouldDisableCheckbox = (name) => {
    const selectedCheckboxCount = Object.values(userData).filter(
      (val) => val === true
    ).length;
    return selectedCheckboxCount >= 3 && !userData[name];
  };

  const handleMobileNumberBlur = () => {
    const isValid = validateMobileNumber(userData.phone_number);
    setIsMobileNumberValid(isValid);
  };
  const handleParentNumberBlur = () => {
    const isValid = validateMobileNumber(userData.parent_phone);
    setIsParentNumberValid(isValid);
  };

  const handleNationalIDBlur = () => {
    const isValid = validateNationalID(userData.national_id);
    setIsNationalIDValid(isValid);
  };

  const handleBirthdateBlur = () => {
    const isValid = validateBirthdate(userData.birth_date);
    setIsBirthdateValid(isValid);
  };

  const validateNationalID = (id) => {
    return id.length === 14 && !isNaN(id);
  };

  const validateBirthdate = (dateString) => {
    return !isNaN(Date.parse(dateString));
  };

  const validateMobileNumber = (number) => {
    const egyptianMobileNumberRegex = /^(01)[0-9]{9}$/;
    return egyptianMobileNumberRegex.test(number);
  };

  function handleSubmit(event) {
    event.preventDefault();
    if (isMobileNumberValid && isNationalIDValid && isBirthdateValid) {
      setRegisterData(userData, "");
      console.log(userData);
      console.log(submittedSuccessfully);
      setShowSuccess(true);
    }
    // window.scrollTo(0, 0);
  }

  return submittedSuccessfully && !FormData.email ? (
    <SignUpSuccess />
  ) : (
    <>
      <div className="mb-md-5">
        <div className="container-fluid mb-md-5 mb-4">
          <div className="mb-5 row">
            <div className="col-12 p-0 position-relative">
              <img src={Banner} className=" banner-about-and-contact" alt="" />
              {localStorage.getItem("i18nextLng") === "en" ? (
                <h2 className="img-overlay">{t("signUpRegisterNowImage")}</h2>
              ) : (
                <h2 className="img-overlay-cont-ar text-nowrap">
                  {t("signUpRegisterNowImage")}
                </h2>
              )}
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div className="container mb-md-5">
          <form action="" onSubmit={handleSubmit}>
            <div className="row d-flex justify-content-center">
              <div className="col-md-8 p-0 col-10">
                <div className="row">
                  <h2 className="mb-5 defaultColor fw-bold">
                    {" "}
                    برنامج اكتشاف الأبطال
                  </h2>
                </div>
                <div className="row mb-4">
                  <h3 className="defaultColor">
                    <span>
                      <img src={no1} width="50" alt="" />
                    </span>{" "}
                    {t("signUpPersonalInfo")}
                  </h3>
                </div>
                <div className="row mb-4">
                  {/* Governorate */}
                  <div className="col-12 col-md-4 mb-md-3 mb-4">
                    <label htmlFor="governate" className="mb-2">
                      المحافظة
                    </label>
                    <div className="d-flex gap-2">
                      {/* First select */}
                      <select
                        id="governate"
                        name="governate"
                        className="w-100 px-2"
                        value={userData.governate}
                        onChange={handleChange}
                        required
                      >
                        <option value="">اختر المحافظة </option>
                        <option value="سوهاج">سوهاج</option>
                        {/* <option value="قنا">قنا</option>
                        <option value="الأقصر">الأقصر</option>
                        <option value="أسوان">أسوان</option> */}
                      </select>

                      {/* Second select */}
                    </div>
                  </div>
                  {userData.governate !== "" && (
                    <div className="col-12 col-md-4 mb-md-3 mb-4 ">
                      <label htmlFor="station" className="mb-2">
                        المركز
                      </label>
                      <div className="d-flex gap-2">
                        {showSohag && (
                          <select
                            className="w-100 px-2"
                            id="station"
                            required
                            name="station"
                            value={userData.station || ""}
                            onChange={handleChange}
                          >
                            <option value="">اختر</option>
                            <option value="مركز طما">مركز طما</option>
                            <option value="دار السلام ">دار السلام </option>
                            <option value="المنشاة">المنشاة</option>
                            <option value="مركز جرجا">مركز جرجا</option>
                            <option value="مركز المراغة">مركز المراغة</option>
                          </select>
                        )}
                        {showAswan && (
                          <select
                            className="w-100 px-2"
                            id="station"
                            required
                            name="station"
                            value={userData.station || ""}
                            onChange={handleChange}
                          >
                            <option value="">اختر</option>
                            <option value="ادفو">ادفو</option>
                            <option value="كوم امبو">كوم امبو</option>
                            <option value="نصر النوبة">نصر النوبة</option>
                            <option value="دراو ">دراو </option>
                            <option value="مركز اسوان ">مركز اسوان </option>
                          </select>
                        )}
                        {showLuxor && (
                          <select
                            className="w-100 px-2"
                            id="station"
                            required
                            name="station"
                            value={userData.station || ""}
                            onChange={handleChange}
                          >
                            <option value="">اختر</option>
                            <option value="ارمنت">ارمنت</option>
                            <option value="اسنا">اسنا</option>
                            <option value="القرنة ">القرنة </option>
                            <option value="البياضية">البياضية</option>
                          </select>
                        )}
                        {showKena && (
                          <select
                            className="w-100 px-2"
                            id="station"
                            required
                            name="station"
                            value={userData.station || ""}
                            onChange={handleChange}
                          >
                            <option value="">اختر</option>
                            <option value="أبو تشت">أبو تشت</option>
                            <option value="الوقف">الوقف</option>
                            <option value="دشنا">دشنا</option>
                            <option value="فرشوط">فرشوط</option>
                            <option value="قوص">قوص</option>
                          </select>
                        )}
                      </div>
                    </div>
                  )}
                  {/* Governorate END*/}

                  <div className="col-12 col-md-4 mb-md-3 mb-4 ">
                    <label htmlFor="village" className="form-label">
                      {" "}
                      {t("signUpVillage")}{" "}
                    </label>
                    <input
                      type="text"
                      required
                      value={userData.village || ""}
                      name="village"
                      onChange={handleChange}
                      className="form-control"
                      id="village"
                    />
                  </div>
                  <div className="col-12 col-md-4 mb-md-3 mb-4 ">
                    <label htmlFor="studentName" className="form-label">
                      {" "}
                      {t("signUpStudentName")}{" "}
                    </label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      id="studentName"
                      value={userData.student_name || ""}
                      name="student_name"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 col-md-4 mb-md-3 mb-4 ">
                    <label htmlFor="age" className="mb-2">
                      السن
                    </label>
                    <div className="d-flex gap-2">
                      <select
                        name="age"
                        required
                        value={userData.age || ""}
                        onChange={handleChange}
                        className="w-100 px-2"
                        id="age"
                      >
                        <option value="">اختر</option>
                        <option value="10-12">10-12</option>
                        <option value="13-15">13-15</option>
                        <option value="16-18">16-18</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-md-3 mb-4 ">
                    <label htmlFor="gender" className="mb-2">
                      النوع
                    </label>
                    <div className="d-flex gap-2">
                      <select
                        name="gender"
                        required
                        value={userData.gender || ""}
                        onChange={handleChange}
                        className="w-100 px-2"
                        id="gender"
                      >
                        <option value="">{t("signUpSelectGender")}</option>
                        <option value="male">ولد</option>
                        <option value="female">بنت</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-md-3 mb-4 ">
                    <label htmlFor="ed_stage" className="mb-2">
                      المرحلة التعليمية
                    </label>
                    <div className="d-flex gap-2">
                      <select
                        required
                        name="ed_stage"
                        value={userData.ed_stage || ""}
                        onChange={handleChange}
                        className="w-100 px-2"
                        id="ed_stage"
                      >
                        <option value="">اختر</option>
                        <option value="ابتدائي">ابتدائي</option>
                        <option value="اعدادي">اعدادي</option>
                        <option value="ثانوي">ثانوي</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-4 mb-md-3 mb-4 ">
                    <label htmlFor="school_name" className="form-label">
                      {" "}
                      {t("signUpSchoolName")}{" "}
                    </label>
                    <input
                      type="text"
                      name="school_name"
                      required
                      value={userData.school_name || ""}
                      onChange={handleChange}
                      className="form-control"
                      id="school_name"
                    />
                  </div>

                  <div className="col-12 col-md-4 mb-md-3 mb-4 ">
                    <label htmlFor="birth_date" className="form-label ">
                      {" "}
                      تاريخ الميلاد
                    </label>
                    <input
                      type="text"
                      name="birth_date"
                      required
                      placeholder="Ex:2024-03-25"
                      value={userData.birth_date || ""}
                      onChange={handleChange}
                      onBlur={handleBirthdateBlur}
                      className="form-control"
                      id="birth_date"
                    />
                    {!isBirthdateValid && (
                      <div className="text-danger">Invalid birth date</div>
                    )}
                  </div>
                  <div className="col-12 col-md-4 mb-md-3 mb-4">
                    <label htmlFor="national_id" className="form-label">
                      {" "}
                      {t("signUpNationalIDnumber")}{" "}
                    </label>
                    <input
                      type="text"
                      name="national_id"
                      required
                      value={userData.national_id || ""}
                      onBlur={handleNationalIDBlur}
                      onChange={handleChange}
                      className="form-control"
                      id="national_id"
                    />
                    {!isNationalIDValid && (
                      <div className="text-danger">Invalid national ID</div>
                    )}
                  </div>

                  <div className="col-12 col-md-4 mb-md-3 mb-4 ">
                    <label htmlFor="phone_number" className="form-label">
                      {" "}
                      {t("signUpPhoneNumber")}{" "}
                    </label>
                    <input
                      type="text"
                      required
                      name="phone_number"
                      onBlur={handleMobileNumberBlur}
                      value={userData.phone_number || ""}
                      onChange={handleChange}
                      className="form-control"
                      id="phone_number"
                    />
                    {!isMobileNumberValid && (
                      <div className="text-danger">Invalid mobile number</div>
                    )}
                  </div>
                  <div className="col-12 col-md-4 mb-md-3 mb-4 ">
                    <label htmlFor="parentPhoneNumber" className="form-label">
                      {" "}
                      {t("signUpParentPhonenumber")}{" "}
                    </label>
                    <input
                      type="text"
                      name="parent_phone"
                      required
                      onBlur={handleParentNumberBlur}
                      value={userData.parent_phone || ""}
                      onChange={handleChange}
                      className="form-control"
                      id="parentPhoneNumber"
                    />
                    {!isParentNumberValid && (
                      <div className="text-danger">Invalid mobile number</div>
                    )}
                  </div>
                </div>
                <hr />
                <div className="row mb-4">
                  <h3 className="defaultColor">
                    <span>
                      <img src={no2} width="50" alt="" />
                    </span>{" "}
                    {t("signUpTalentDetails")}
                  </h3>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 mb-md-3 mb-4 ">
                    <label htmlFor="talent" className="mb-2">
                      الموهبة ( اختار موهبة واحدة فقط من القائمة )
                    </label>
                    <div className="d-flex gap-2">
                      <select
                        name="talent"
                        required
                        value={userData.talent || ""}
                        onChange={handleChange}
                        className="w-100 px-2"
                        id="talent"
                      >
                        <option value="">اختر</option>
                        <option value="الرياضة">الرياضة</option>
                        <option value="الفن">الفن</option>
                        <option value="التكنولوجيا">التكنولوجيا</option>
                      </select>
                    </div>
                  </div>

                  {showSpecify && (
                    <div className="col-12 mb-md-3 mb-4 ">
                      <label htmlFor="gender" className="mb-3">
                        إذا كان اختيارك هو الرياضة اختار 3 رياضات فقط من
                        الرياضات الآتية
                      </label>
                      <div className="d-flex flex-row">
                        <div className="form-check d-flex flex-row-reverse gap-md-3 gap-2 ">
                          <label
                            className="form-check-label mx-0 mx-md-2  text-nowrap  "
                            htmlFor="wrestling"
                          >
                            المصارعة
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            disabled={shouldDisableCheckbox("wrestling")}
                            id="wrestling"
                            checked={userData.wrestling}
                            onChange={handleChange}
                            name="wrestling"
                            required
                          />
                        </div>
                        <div className="form-check d-flex flex-row-reverse gap-md-3 gap-2 ">
                          <label
                            className="form-check-label mx-0 mx-md-2  text-nowrap  "
                            htmlFor="judo"
                          >
                            الجودو
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            required
                            disabled={shouldDisableCheckbox("judo")}
                            id="judo"
                            checked={userData.judo}
                            onChange={handleChange}
                            name="judo"
                          />
                        </div>
                        <div className="form-check d-flex flex-row-reverse gap-md-3 gap-2 ">
                          <label
                            className="form-check-label mx-0 mx-md-2  text-nowrap "
                            htmlFor="weightlifting"
                          >
                            رفع الاثقال
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            required
                            disabled={shouldDisableCheckbox("weightlifting")}
                            checked={userData.weightlifting}
                            onChange={handleChange}
                            name="weightlifting"
                            id="weightlifting"
                          />
                        </div>
                        <div className="form-check d-flex flex-row-reverse gap-md-3 gap-2 ">
                          <label
                            className="form-check-label mx-0 mx-md-2  text-nowrap "
                            htmlFor="handball"
                          >
                            كرة اليد
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            required
                            disabled={shouldDisableCheckbox("handball")}
                            checked={userData.handball}
                            onChange={handleChange}
                            name="handball"
                            id="handball"
                          />
                        </div>
                        <div className="form-check d-flex flex-row-reverse gap-md-3 gap-2 ">
                          <label
                            className="form-check-label text-nowrap mx-2"
                            htmlFor="football"
                          >
                            كرة القدم
                          </label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            disabled={shouldDisableCheckbox("football")}
                            required
                            checked={userData.football}
                            onChange={handleChange}
                            name="football"
                            id="football"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {showArt && (
                    <div className="col-12 col-md-6 mb-md-3 mb-4 ">
                      <label htmlFor="talent_type" className="mb-2">
                        اذا كان اختيارك هو الفن اختار نوع الموهبة
                      </label>
                      <div className="d-flex gap-2">
                        <select
                          name="talent_type"
                          required
                          value={userData.talent_type || ""}
                          onChange={handleChange}
                          className="w-100 px-2"
                        >
                          <option value="">اختر</option>
                          <option value="المسرح">المسرح</option>
                          <option value="العزف">العزف</option>
                          <option value="الغناء">الغناء</option>
                          <option value="الرسم">الرسم</option>
                        </select>
                      </div>
                    </div>
                  )}

                  {showTech && (
                    <>
                      <div className="col-12 col-md-6 mb-md-3 mb-4 ">
                        <label htmlFor="tech_english" className="mb-2">
                          إذا كان اختيارك هو التكنولوجيا اختار اخر درجة حصلت
                          عليها في مادة الانجليزي
                        </label>
                        <div className="d-flex gap-2">
                          <select
                            name="tech_english"
                            required
                            value={userData.tech_english || ""}
                            onChange={handleChange}
                            className="w-100 px-2"
                          >
                            <option value="">اختر</option>
                            <option value="70-79 درجة">70-79 درجة</option>
                            <option value="80-89 درجة">80-89 درجة</option>
                            <option value="90-99 درجة">90-99 درجة</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mb-md-3 mb-4 ">
                        <label htmlFor="tech_math" className="mb-2">
                          إذا كان اختيارك هو التكنولوجيا اختار اخر درجة حصلت
                          عليها في مادة الرياضيات
                        </label>
                        <div className="d-flex gap-2">
                          <select
                            name="tech_math"
                            required
                            value={userData.tech_math || ""}
                            onChange={handleChange}
                            className="w-100 px-2"
                          >
                            <option value="">اختر</option>
                            <option value="70-79 درجة">70-79 درجة</option>
                            <option value="80-89 درجة">80-89 درجة</option>
                            <option value="90-99 درجة">90-99 درجة</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mb-md-3 mb-4 ">
                        <label htmlFor="tech_science" className="mb-2">
                          إذا كان اختيارك هو التكنولوجيا اختار اخر درجة حصلت
                          عليها في مادة العلوم
                        </label>
                        <div className="d-flex gap-2">
                          <select
                            name="tech_science"
                            required
                            value={userData.tech_science || ""}
                            onChange={handleChange}
                            className="w-100 px-2"
                          >
                            <option value="">اختر</option>
                            <option value="70-79 درجة">70-79 درجة</option>
                            <option value="80-89 درجة">80-89 درجة</option>
                            <option value="90-99 درجة">90-99 درجة</option>
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-12 col-md-6 mb-md-3 mb-4 ">
                    <label htmlFor="participate_times" className="mb-2">
                      كم مرة شاركت في مسابقات تتعلق بموهبتك الرياضية / الفنية /
                      التكنولوجية؟
                    </label>
                    <div className="d-flex gap-2">
                      <select
                        name="participate_times"
                        required
                        value={userData.participate_times || ""}
                        onChange={handleChange}
                        className="w-100 px-2"
                      >
                        <option value="">اختر</option>
                        <option value=" ولا مرة">ولا مرة</option>
                        <option value="من مرة لمرتين">من مرة لمرتين</option>
                        <option value="من 3-5 مرات">من 3-5 مرات</option>
                        <option value="(أكثر من 5 مرات)">
                          (أكثر من 5 مرات)
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 mb-4">
                    <p className="defaultColor fw-bold">
                      هل حصلت على شهادة أو ميدالية نتيجة مشاركتك
                    </p>
                    <div className="d-flex gap-5">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          required
                          type="radio"
                          name="competition_participate"
                          id="comptYes"
                          value="1"
                          onChange={handleChange}
                          checked={userData.competition_participate === "1"}
                        />
                        <label
                          className="form-check-label text-dark"
                          htmlFor="comptYes"
                        >
                          {" "}
                          {t("signUpYes")}{" "}
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          required
                          type="radio"
                          name="competition_participate"
                          id="comptNo"
                          value="0"
                          onChange={handleChange}
                          checked={userData.competition_participate === "0"}
                        />
                        <label
                          className="form-check-label text-dark"
                          htmlFor="comptNo"
                        >
                          {" "}
                          {t("signUpNo")}{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-md-3 mb-4 ">
                    <label htmlFor="practice_a_week" className="mb-2">
                      بتمارس موهبتك الرياضية / الفنية / التكنولوجية جوه أو بره
                      المدرسة كم مرة في الاسبوع ؟
                    </label>
                    <div className="d-flex gap-2">
                      <select
                        required
                        name="practice_a_week"
                        value={userData.practice_a_week || ""}
                        onChange={handleChange}
                        id="practice_a_week"
                        className="w-100 px-2"
                      >
                        <option value="  ولا مره"> ولا مره</option>
                        <option value=" نادرا (1-2 مرات)">
                          {" "}
                          نادرا (1-2 مرات)
                        </option>
                        <option value="احيانا (3-5 مرات)">
                          احيانا (3-5 مرات)
                        </option>
                        <option value=" كثير (اكثر من 5 مرات)">
                          {" "}
                          كثير (اكثر من 5 مرات)
                        </option>
                      </select>
                    </div>
                  </div>

                  {showCertMedal && (
                    <div className="col-12 col-md-6 mb-md-3 mb-4 d-flex flex-md-row flex-column gap-3">
                      <input
                        type="file"
                        accept="video/*"
                        className="form-control"
                        required
                      />
                    </div>
                  )}
                </div>
                <hr />
                <div className="row mb-4">
                  <h3 className="defaultColor">
                    <span>
                      <img src={no3} width="50" alt="" />
                    </span>{" "}
                    الأسئلة العامة
                  </h3>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6 mb-md-3 mb-4 ">
                    <label htmlFor="bullying" className="mb-2">
                      كم مرة اتعرضت للتنمر أو شوفت موقف بيتعرض فيه طلاب آخرين
                      للتنمر
                    </label>
                    <div className="d-flex gap-2">
                      <select
                        name="bullying"
                        required
                        value={userData.bullying || ""}
                        onChange={handleChange}
                        className="w-100 px-2"
                        id="bullying"
                      >
                        <option value="">اختر</option>
                        <option value=" ولا مره"> ولا مره</option>
                        <option value="  نادرا (1-2 مرات)">
                          {" "}
                          نادرا (1-2 مرات)
                        </option>
                        <option value="احيانا (3-5 مرات)">
                          {" "}
                          احيانا (3-5 مرات)
                        </option>
                        <option value="كثير (اكثر من 5 مرات)">
                          كثير (اكثر من 5 مرات)
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-md-3 mb-4 ">
                    <label htmlFor="communicate" className="mb-2">
                      بعرف اتواصل مع الآخرين بسهولة و أطلب المساعدة من اللي
                      حواليا لما ببقي متضايق أو بواجه مشكلة
                    </label>
                    <div className="d-flex gap-2">
                      <select
                        name="communicate"
                        required
                        value={userData.communicate || ""}
                        onChange={handleChange}
                        className="w-100 px-2"
                        id="communicate"
                      >
                        <option value="">اختر</option>
                        <option value="دايماً">دايماً</option>
                        <option value="احياناً">احياناً</option>
                        <option value="لا ابدا">لا ابدا</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-md-3 mb-4 ">
                    <label htmlFor="support" className="mb-2">
                      إلى أي مدي بتحس ان أهلك بيفهموا احتياجاتك النفسية و
                      بيدعموك في تحقيق أهدافك و تطوير مهاراتك؟
                    </label>
                    <div className="d-flex gap-2">
                      <select
                        name="support"
                        required
                        value={userData.support || ""}
                        onChange={handleChange}
                        className="w-100 px-2"
                        id="support"
                      >
                        <option value="">اختر</option>
                        <option value="دايماً">دايماً</option>
                        <option value="احياناً">احياناً</option>
                        <option value="لا ابدا">لا ابدا</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-md-3 mb-4 ">
                    <label htmlFor="school_support" className="mb-2">
                      إلى أي مدي بتحس ان المدرسه و المدرسين بيساعدوك و يدعموك في
                      اكتشاف موهبتك و تطويرها؟
                    </label>
                    <div className="d-flex gap-2">
                      <select
                        name="school_support"
                        required
                        value={userData.school_support || ""}
                        onChange={handleChange}
                        className="w-100 px-2"
                        id="school_support"
                      >
                        <option value="">اختر</option>
                        <option value="دايماً">دايماً</option>
                        <option value="احياناً">احياناً</option>
                        <option value="لا ابدا">لا ابدا</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-12 mb-md-3 my-5 d-flex flex-md-row flex-column gap-3">
                    <label htmlFor="video_file" className="mb-2">
                      تقدر من هنا تحمل فيديو أو صورة لك وأنت بتمارس موهبتك أو
                      صورة لشهادة أو ميدالية استلمتها
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      name="video_file"
                      id="video_file"
                      accept="video/*,image/*"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-12 col-md-6 mb-md-3 mb-4 d-flex flex-md-row flex-column gap-3">
                    <button
                      type="submit"
                      className="btn-default border-0 px-4 py-2"
                    >
                      {" "}
                      {t("signUpRegisterNow")}{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Signup;

// import React, { useState, useContext } from "react";

// import no1 from "./signupImgs/Number-1.png";
// import no2 from "./signupImgs/Numbers-2.png";
// import no3 from "./signupImgs/Number-3.png";
// // import { customerContext } from "../../axios";
// import SignUpSuccess from "./SignUpSuccess";
// import { useTranslation } from "react-i18next";
// import Banner from "./signupImgs/Banner-Register_20(1).802d40c76cb53907c4ea.png";
// import { customerContext } from "../../axios";

// const Signup = () => {
//   const [showSuccess, setShowSuccess] = useState(false);
//   const [showSpecify, setShowSpecify] = useState(false);
//   const [showArt, setShowArt] = useState(false);
//   const [showTech, setShowTech] = useState(false);

//   const [firstSelectValue, setFirstSelectValue] = useState("");
//   const [secondSelectOptions, setSecondSelectOptions] = useState([]);
//   const { t } = useTranslation();

//   const { setRegisterData, submittedSuccessfully } =
//     useContext(customerContext);

//   const [userData, setuserData] = useState({
//     governate: "",
//     station: "",
//     village: "",
//     student_name: "",
//     age: "",
//     gender: "",
//     talent: "",
//     bullying: "",
//     support: "",
//     ed_stage: "",
//     talent_type: "",
//     practice_a_week: "",
//     wrestling: false,
//     judo: false,
//     weightlifting: false,
//     handball: false,
//     football: false,
//     participate_times: "",
//     school_support: "",
//     tech_science: "",
//     tech_math: "",
//     tech_english: "",
//     communicate: "",
//     birth_date: "",
//     national_id: "",
//     school_name: "",
//     phone_number: "",
//     parent_phone: "",
//     competition_participate: 0,
//     video_file: null,
//   });

//   let options = [];
//   function handleChange(event) {
//     const selectedValue = event.target.value;

//     setFirstSelectValue(selectedValue);

//     if (secondSelectOptions.length === 0) {
//       if (selectedValue === "سوهاج") {
//         options = [
//           "اختر",
//           "مركز سوهاج",
//           "مركز المنشاة",
//           "مركز دار السلام",
//           "مركز جرجا",
//           "مركز العسيرات",
//         ];
//       } else if (selectedValue === "الأقصر") {
//         options = ["اختر", "ارمنت", "اسنا", "القرنة", "البياضية"];
//       } else if (selectedValue === "أسوان") {
//         options = [
//           "اختر",
//           "ادفو",
//           "كوم امبو",
//           "نصر النوبة",
//           "دراو",
//           "مركز أسوان",
//         ];
//       } else if (selectedValue === "قنا") {
//         options = ["اختر", "أبوتشت", "الوقف", "دشنا", "فرشوط", "قوص"];
//       }
//       setSecondSelectOptions(options);
//     }
//     const { name, value, type, checked, files } = event.target;

//     if (value === "الرياضة" && name === "talent") {
//       setShowSpecify(true);
//       setShowArt(false);
//       setShowTech(false);
//     }
//     if (value === "الفن" && name === "talent") {
//       setShowArt(true);
//       setShowTech(false);
//       setShowSpecify(false);
//     }
//     if (value === "التكنولوجيا" && name === "talent") {
//       setShowTech(true);
//       setShowArt(false);
//       setShowSpecify(false);
//     }

//     if (type === "checkbox" && checked) {
//       const selectedCheckboxCount = Object.values(userData).filter(
//         (val) => val === true
//       ).length;
//       if (selectedCheckboxCount >= 3) {
//         return;
//       }
//     }
//     if (type === "file" && files.length > 0) {
//       console.log(files);
//       setuserData((prevFormData) => ({
//         ...prevFormData,
//         [name]: files[0],
//       }));
//     } else {
//       setuserData((prevFormData) => {
//         return {
//           ...prevFormData,
//           [name]: type === "checkbox" ? checked : value,
//         };
//       });
//     }
//   }

//   const shouldDisableCheckbox = (name) => {
//     const selectedCheckboxCount = Object.values(userData).filter(
//       (val) => val === true
//     ).length;
//     return selectedCheckboxCount >= 3 && !userData[name];
//   };

//   function handleSubmit(event) {
//     event.preventDefault();
//     setRegisterData(userData, "");
//     console.log(userData);
//     console.log(submittedSuccessfully);
//     setShowSuccess(true);
//     // window.scrollTo(0, 0);
//   }

//   return submittedSuccessfully && !FormData.email ? (
//     <SignUpSuccess />
//   ) : (
//     <>
//       <div className="mb-md-5">
//         <div className="container-fluid mb-md-5 mb-4">
//           <div className="mb-5 row">
//             <div className="col-12 p-0 position-relative">
//               <img src={Banner} className=" banner-about-and-contact" alt="" />
//               {localStorage.getItem("i18nextLng") === "en" ? (
//                 <h2 className="img-overlay">{t("signUpRegisterNowImage")}</h2>
//               ) : (
//                 <h2 className="img-overlay-cont-ar text-nowrap">
//                   {t("signUpRegisterNowImage")}
//                 </h2>
//               )}
//             </div>
//           </div>
//         </div>
//         <br />
//         <br />
//         <br />
//         <div className="container mb-md-5">
//           <form action="" onSubmit={handleSubmit}>
//             <div className="row d-flex justify-content-center">
//               <div className="col-md-8 p-0 col-10">
//                 <div className="row">
//                   <h2 className="mb-5 defaultColor fw-bold">
//                     {" "}
//                     برنامج اكتشاف الأبطال
//                   </h2>
//                 </div>
//                 <div className="row mb-4">
//                   <h3 className="defaultColor">
//                     <span>
//                       <img src={no1} width="50" alt="" />
//                     </span>{" "}
//                     {t("signUpPersonalInfo")}
//                   </h3>
//                 </div>
//                 <div className="row mb-4">
//                   {/* Governorate */}
//                   <div className="col-12 col-md-4 mb-md-3 mb-4">
//                     <label htmlFor="governate" className="mb-2">
//                       المحافظة
//                     </label>
//                     <div className="d-flex gap-2">
//                       {/* First select */}
//                       <select
//                         id="governate"
//                         name="governate"
//                         required
//                         className="w-100 px-2"
//                         value={userData.governate}
//                         onChange={handleChange}
//                       >
//                         <option value="">اختر المحافظة </option>
//                         <option value="سوهاج">سوهاج</option>
//                         <option value="قنا">قنا</option>
//                         <option value="الأقصر">الأقصر</option>
//                         <option value="أسوان">أسوان</option>
//                       </select>

//                       {/* Second select */}
//                     </div>
//                   </div>
//                   {userData.governate !== "" && (
//                     <div className="col-12 col-md-4 mb-md-3 mb-4 ">
//                       <label htmlFor="station" className="mb-2">
//                         المركز
//                       </label>
//                       <div className="d-flex gap-2">
//                         <select
//                           className="w-100 px-2"
//                           id="station"
//                           required
//                           name="station"
//                           value={userData.station || ""}
//                           onChange={handleChange}
//                         >
//                           {secondSelectOptions.map((option, index) => (
//                             <option key={index} value={option}>
//                               {option}
//                             </option>
//                           ))}
//                         </select>
//                       </div>
//                     </div>
//                   )}
//                   {/* Governorate END*/}

//                   <div className="col-12 col-md-4 mb-md-3 mb-4 ">
//                     <label htmlFor="village" className="form-label">
//                       {" "}
//                       {t("signUpVillage")}{" "}
//                     </label>
//                     <input
//                       type="text"
//                       required
//                       value={userData.village || ""}
//                       name="village"
//                       onChange={handleChange}
//                       className="form-control"
//                       id="village"
//                     />
//                   </div>
//                   <div className="col-12 col-md-4 mb-md-3 mb-4 ">
//                     <label htmlFor="studentName" className="form-label">
//                       {" "}
//                       {t("signUpStudentName")}{" "}
//                     </label>
//                     <input
//                       required
//                       type="text"
//                       className="form-control"
//                       id="studentName"
//                       value={userData.student_name || ""}
//                       name="student_name"
//                       onChange={handleChange}
//                     />
//                   </div>
//                   <div className="col-12 col-md-4 mb-md-3 mb-4 ">
//                     <label htmlFor="age" className="mb-2">
//                       السن
//                     </label>
//                     <div className="d-flex gap-2">
//                       <select
//                         name="age"
//                         required
//                         value={userData.age || ""}
//                         onChange={handleChange}
//                         className="w-100 px-2"
//                         id="age"
//                       >
//                         <option>اختر</option>
//                         <option value="10-12">10-12</option>
//                         <option value="13-15">13-15</option>
//                         <option value="16-18">16-18</option>
//                       </select>
//                     </div>
//                   </div>
//                   <div className="col-12 col-md-4 mb-md-3 mb-4 ">
//                     <label htmlFor="gender" className="mb-2">
//                       النوع
//                     </label>
//                     <div className="d-flex gap-2">
//                       <select
//                         name="gender"
//                         required
//                         value={userData.gender || ""}
//                         onChange={handleChange}
//                         className="w-100 px-2"
//                         id="gender"
//                       >
//                         <option>{t("signUpSelectGender")}</option>
//                         <option value="male">ولد</option>
//                         <option value="female">بنت</option>
//                       </select>
//                     </div>
//                   </div>
//                   <div className="col-12 col-md-4 mb-md-3 mb-4 ">
//                     <label htmlFor="ed_stage" className="mb-2">
//                       المرحلة التعليمية
//                     </label>
//                     <div className="d-flex gap-2">
//                       <select
//                         required
//                         name="ed_stage"
//                         value={userData.ed_stage || ""}
//                         onChange={handleChange}
//                         className="w-100 px-2"
//                         id="ed_stage"
//                       >
//                         <option>اختر</option>
//                         <option value="ابتدائي">ابتدائي</option>
//                         <option value="اعدادي">اعدادي</option>
//                         <option value="ثانوي">ثانوي</option>
//                       </select>
//                     </div>
//                   </div>
//                   <div className="col-12 col-md-4 mb-md-3 mb-4 ">
//                     <label htmlFor="school_name" className="form-label">
//                       {" "}
//                       {t("signUpSchoolName")}{" "}
//                     </label>
//                     <input
//                       type="text"
//                       name="school_name"
//                       required
//                       value={userData.school_name || ""}
//                       onChange={handleChange}
//                       className="form-control"
//                       id="school_name"
//                     />
//                   </div>

//                   <div className="col-12 col-md-4 mb-md-3 mb-4 ">
//                     <label htmlFor="birth_date" className="form-label ">
//                       {" "}
//                       تاريخ الميلاد
//                     </label>
//                     <input
//                       type="text"
//                       name="birth_date"
//                       required
//                       value={userData.birth_date || ""}
//                       onChange={handleChange}
//                       className="form-control"
//                       id="birth_date"
//                     />
//                   </div>
//                   <div className="col-12 col-md-4 mb-md-3 mb-4">
//                     <label htmlFor="national_id" className="form-label">
//                       {" "}
//                       {t("signUpNationalIDnumber")}{" "}
//                     </label>
//                     <input
//                       type="text"
//                       name="national_id"
//                       required
//                       value={userData.national_id || ""}
//                       onChange={handleChange}
//                       className="form-control"
//                       id="national_id"
//                     />
//                   </div>

//                   <div className="col-12 col-md-4 mb-md-3 mb-4 ">
//                     <label htmlFor="phone_number" className="form-label">
//                       {" "}
//                       {t("signUpPhoneNumber")}{" "}
//                     </label>
//                     <input
//                       type="text"
//                       required
//                       name="phone_number"
//                       value={userData.phone_number || ""}
//                       onChange={handleChange}
//                       className="form-control"
//                       id="phone_number"
//                     />
//                   </div>
//                   <div className="col-12 col-md-4 mb-md-3 mb-4 ">
//                     <label htmlFor="parentPhoneNumber" className="form-label">
//                       {" "}
//                       {t("signUpParentPhonenumber")}{" "}
//                     </label>
//                     <input
//                       type="text"
//                       name="parent_phone"
//                       required
//                       value={userData.parent_phone || ""}
//                       onChange={handleChange}
//                       className="form-control"
//                       id="parentPhoneNumber"
//                     />
//                   </div>
//                 </div>
//                 <hr />
//                 <div className="row mb-4">
//                   <h3 className="defaultColor">
//                     <span>
//                       <img src={no2} width="50" alt="" />
//                     </span>{" "}
//                     {t("signUpTalentDetails")}
//                   </h3>
//                 </div>
//                 <div className="row">
//                   <div className="col-12 col-md-6 mb-md-3 mb-4 ">
//                     <label htmlFor="talent" className="mb-2">
//                       الموهبة ( اختار موهبة واحدة فقط من القائمة )
//                     </label>
//                     <div className="d-flex gap-2">
//                       <select
//                         name="talent"
//                         required
//                         value={userData.talent || ""}
//                         onChange={handleChange}
//                         className="w-100 px-2"
//                         id="talent"
//                       >
//                         <option>اختر</option>
//                         <option value="الرياضة">الرياضة</option>
//                         <option value="الفن">الفن</option>
//                         <option value="التكنولوجيا">التكنولوجيا</option>
//                       </select>
//                     </div>
//                   </div>

//                   {showSpecify && (
//                     <div className="col-12 col-md-6 mb-md-3 mb-4 ">
//                       <label htmlFor="gender" className="mb-3">
//                         إذا كان اختيارك هو الرياضة اختار 3 رياضات فقط من
//                         الرياضات الآتية
//                       </label>
//                       <div className="d-flex ">
//                         <div className="form-check d-flex flex-row-reverse gap-3">
//                           <label
//                             className="form-check-label mx-0 mx-md-2  text-nowrap  "
//                             htmlFor="wrestling"
//                           >
//                             المصارعة
//                           </label>
//                           <input
//                             className="form-check-input"
//                             type="checkbox"
//                             required
//                             disabled={shouldDisableCheckbox("wrestling")}
//                             id="wrestling"
//                             checked={userData.wrestling}
//                             onChange={handleChange}
//                             name="wrestling"
//                           />
//                         </div>
//                         <div className="form-check d-flex flex-row-reverse gap-3">
//                           <label
//                             className="form-check-label mx-0 mx-md-2  text-nowrap  "
//                             htmlFor="judo"
//                           >
//                             الجودو
//                           </label>
//                           <input
//                             className="form-check-input"
//                             type="checkbox"
//                             required
//                             disabled={shouldDisableCheckbox("judo")}
//                             id="judo"
//                             checked={userData.judo}
//                             onChange={handleChange}
//                             name="judo"
//                           />
//                         </div>
//                         <div className="form-check d-flex flex-row-reverse gap-4">
//                           <label
//                             className="form-check-label text-nowrap mx-2"
//                             htmlFor="weightlifting"
//                           >
//                             رفع الاثقال
//                           </label>
//                           <input
//                             className="form-check-input"
//                             type="checkbox"
//                             required
//                             disabled={shouldDisableCheckbox("weightlifting")}
//                             checked={userData.weightlifting}
//                             onChange={handleChange}
//                             name="weightlifting"
//                             id="weightlifting"
//                           />
//                         </div>
//                         <div className="form-check d-flex flex-row-reverse gap-4">
//                           <label
//                             className="form-check-label text-nowrap mx-2"
//                             htmlFor="handball"
//                           >
//                             كرة اليد
//                           </label>
//                           <input
//                             className="form-check-input"
//                             type="checkbox"
//                             required
//                             disabled={shouldDisableCheckbox("handball")}
//                             checked={userData.handball}
//                             onChange={handleChange}
//                             name="handball"
//                             id="handball"
//                           />
//                         </div>
//                         <div className="form-check d-flex flex-row-reverse gap-4">
//                           <label
//                             className="form-check-label text-nowrap mx-2"
//                             htmlFor="football"
//                           >
//                             كرة القدم
//                           </label>
//                           <input
//                             className="form-check-input"
//                             type="checkbox"
//                             required
//                             disabled={shouldDisableCheckbox("football")}
//                             checked={userData.football}
//                             onChange={handleChange}
//                             name="football"
//                             id="football"
//                           />
//                         </div>
//                       </div>
//                     </div>
//                   )}

//                   {showArt && (
//                     <div className="col-12 col-md-6 mb-md-3 mb-4 ">
//                       <label htmlFor="talent_type" className="mb-2">
//                         اذا كان اختيارك هو الفن اختار نوع الموهبة
//                       </label>
//                       <div className="d-flex gap-2">
//                         <select
//                           name="talent_type"
//                           required
//                           value={userData.talent_type || ""}
//                           onChange={handleChange}
//                           className="w-100 px-2"
//                         >
//                           <option value="">اختر</option>
//                           <option value="المسرح">المسرح</option>
//                           <option value="العزف">العزف</option>
//                           <option value="الغناء">الغناء</option>
//                           <option value="الرسم">الرسم</option>
//                         </select>
//                       </div>
//                     </div>
//                   )}

//                   {showTech && (
//                     <>
//                       <div className="col-12 col-md-6 mb-md-3 mb-4 ">
//                         <label htmlFor="tech_english" className="mb-2">
//                           إذا كان اختيارك هو التكنولوجيا اختار اخر درجة حصلت
//                           عليها في مادة الانجليزي
//                         </label>
//                         <div className="d-flex gap-2">
//                           <select
//                             name="tech_english"
//                             required
//                             value={userData.tech_english || ""}
//                             onChange={handleChange}
//                             className="w-100 px-2"
//                           >
//                             <option value="">اختر</option>
//                             <option value="70-79 درجة">70-79 درجة</option>
//                             <option value="80-89 درجة">80-89 درجة</option>
//                             <option value="90-99 درجة">90-99 درجة</option>
//                           </select>
//                         </div>
//                       </div>
//                       <div className="col-12 col-md-6 mb-md-3 mb-4 ">
//                         <label htmlFor="tech_math" className="mb-2">
//                           إذا كان اختيارك هو التكنولوجيا اختار اخر درجة حصلت
//                           عليها في مادة الرياضيات
//                         </label>
//                         <div className="d-flex gap-2">
//                           <select
//                             name="tech_math"
//                             required
//                             value={userData.tech_math || ""}
//                             onChange={handleChange}
//                             className="w-100 px-2"
//                           >
//                             <option value="">اختر</option>
//                             <option value="70-79 درجة">70-79 درجة</option>
//                             <option value="80-89 درجة">80-89 درجة</option>
//                             <option value="90-99 درجة">90-99 درجة</option>
//                           </select>
//                         </div>
//                       </div>
//                       <div className="col-12 col-md-6 mb-md-3 mb-4 ">
//                         <label htmlFor="tech_science" className="mb-2">
//                           إذا كان اختيارك هو التكنولوجيا اختار اخر درجة حصلت
//                           عليها في مادة العلوم
//                         </label>
//                         <div className="d-flex gap-2">
//                           <select
//                             name="tech_science"
//                             required
//                             value={userData.tech_science || ""}
//                             onChange={handleChange}
//                             className="w-100 px-2"
//                           >
//                             <option value="">اختر</option>
//                             <option value="70-79 درجة">70-79 درجة</option>
//                             <option value="80-89 درجة">80-89 درجة</option>
//                             <option value="90-99 درجة">90-99 درجة</option>
//                           </select>
//                         </div>
//                       </div>
//                     </>
//                   )}
//                   <div className="col-12 col-md-6 mb-md-3 mb-4 ">
//                     <label htmlFor="participate_times" className="mb-2">
//                       كم مرة شاركت في مسابقات تتعلق بموهبتك الرياضية / الفنية /
//                       التكنولوجية؟
//                     </label>
//                     <div className="d-flex gap-2">
//                       <select
//                         name="participate_times"
//                         required
//                         value={userData.participate_times || ""}
//                         onChange={handleChange}
//                         className="w-100 px-2"
//                       >
//                         <option value="">اختر</option>
//                         <option value=" ولا مرة">ولا مرة</option>
//                         <option value="من مرة لمرتين">من مرة لمرتين</option>
//                         <option value="من 3-5 مرات">من 3-5 مرات</option>
//                         <option value="(أكثر من 5 مرات)">
//                           (أكثر من 5 مرات)
//                         </option>
//                       </select>
//                     </div>
//                   </div>

//                   <div className="col-12 col-md-6 mb-4">
//                     <p className="defaultColor fw-bold">
//                       هل حصلت على شهادة أو ميدالية نتيجة مشاركتك
//                     </p>
//                     <div className="d-flex gap-5">
//                       <div className="form-check form-check-inline">
//                         <input
//                           className="form-check-input"
//                           required
//                           type="radio"
//                           name="competition_participate"
//                           id="comptYes"
//                           value="1"
//                           onChange={handleChange}
//                           checked={userData.competition_participate === "1"}
//                         />
//                         <label
//                           className="form-check-label text-dark"
//                           htmlFor="comptYes"
//                         >
//                           {" "}
//                           {t("signUpYes")}{" "}
//                         </label>
//                       </div>
//                       <div className="form-check form-check-inline">
//                         <input
//                           className="form-check-input"
//                           required
//                           type="radio"
//                           name="competition_participate"
//                           id="comptNo"
//                           value="0"
//                           onChange={handleChange}
//                           checked={userData.competition_participate === "0"}
//                         />
//                         <label
//                           className="form-check-label text-dark"
//                           htmlFor="comptNo"
//                         >
//                           {" "}
//                           {t("signUpNo")}{" "}
//                         </label>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="col-12 col-md-6 mb-md-3 mb-4 ">
//                     <label htmlFor="practice_a_week" className="mb-2">
//                       بتمارس موهبتك الرياضية / الفنية / التكنولوجية جوه أو بره
//                       المدرسة كم مرة في الاسبوع ؟
//                     </label>
//                     <div className="d-flex gap-2">
//                       <select
//                         required
//                         name="practice_a_week"
//                         value={userData.practice_a_week || ""}
//                         onChange={handleChange}
//                         id="practice_a_week"
//                         className="w-100 px-2"
//                       >
//                         <option value="  ولا مره"> ولا مره</option>
//                         <option value=" نادرا (1-2 مرات)">
//                           {" "}
//                           نادرا (1-2 مرات)
//                         </option>
//                         <option value="احيانا (3-5 مرات)">
//                           احيانا (3-5 مرات)
//                         </option>
//                         <option value=" كثير (اكثر من 5 مرات)">
//                           {" "}
//                           كثير (اكثر من 5 مرات)
//                         </option>
//                       </select>
//                     </div>
//                   </div>
//                 </div>
//                 <hr />
//                 <div className="row mb-4">
//                   <h3 className="defaultColor">
//                     <span>
//                       <img src={no3} width="50" alt="" />
//                     </span>{" "}
//                     الأسئلة العامة
//                   </h3>
//                 </div>
//                 <div className="row">
//                   <div className="col-12 col-md-6 mb-md-3 mb-4 ">
//                     <label htmlFor="bullying" className="mb-2">
//                       كم مرة اتعرضت للتنمر أو شوفت موقف بيتعرض فيه طلاب آخرين
//                       للتنمر
//                     </label>
//                     <div className="d-flex gap-2">
//                       <select
//                         name="bullying"
//                         required
//                         value={userData.bullying || ""}
//                         onChange={handleChange}
//                         className="w-100 px-2"
//                         id="bullying"
//                       >
//                         <option>اختر</option>
//                         <option value=" ولا مره"> ولا مره</option>
//                         <option value="  نادرا (1-2 مرات)">
//                           {" "}
//                           نادرا (1-2 مرات)
//                         </option>
//                         <option value="احيانا (3-5 مرات)">
//                           {" "}
//                           احيانا (3-5 مرات)
//                         </option>
//                         <option value="كثير (اكثر من 5 مرات)">
//                           كثير (اكثر من 5 مرات)
//                         </option>
//                       </select>
//                     </div>
//                   </div>
//                   <div className="col-12 col-md-6 mb-md-3 mb-4 ">
//                     <label htmlFor="communicate" className="mb-2">
//                       بعرف اتواصل مع الآخرين بسهولة و أطلب المساعدة من اللي
//                       حواليا لما ببقي متضايق أو بواجه مشكلة
//                     </label>
//                     <div className="d-flex gap-2">
//                       <select
//                         name="communicate"
//                         required
//                         value={userData.communicate || ""}
//                         onChange={handleChange}
//                         className="w-100 px-2"
//                         id="communicate"
//                       >
//                         <option>اختر</option>
//                         <option value="دايماً">دايماً</option>
//                         <option value="احياناً">احياناً</option>
//                         <option value="لا ابدا">لا ابدا</option>
//                       </select>
//                     </div>
//                   </div>
//                   <div className="col-12 col-md-6 mb-md-3 mb-4 ">
//                     <label htmlFor="support" className="mb-2">
//                       إلى أي مدي بتحس ان أهلك بيفهموا احتياجاتك النفسية و
//                       بيدعموك في تحقيق أهدافك و تطوير مهاراتك؟
//                     </label>
//                     <div className="d-flex gap-2">
//                       <select
//                         name="support"
//                         required
//                         value={userData.support || ""}
//                         onChange={handleChange}
//                         className="w-100 px-2"
//                         id="support"
//                       >
//                         <option>اختر</option>
//                         <option value="دايماً">دايماً</option>
//                         <option value="احياناً">احياناً</option>
//                         <option value="لا ابدا">لا ابدا</option>
//                       </select>
//                     </div>
//                   </div>
//                   <div className="col-12 col-md-6 mb-md-3 mb-4 ">
//                     <label htmlFor="school_support" className="mb-2">
//                       إلى أي مدي بتحس ان المدرسه و المدرسين بيساعدوك و يدعموك في
//                       اكتشاف موهبتك و تطويرها؟
//                     </label>
//                     <div className="d-flex gap-2">
//                       <select
//                         name="school_support"
//                         required
//                         value={userData.school_support || ""}
//                         onChange={handleChange}
//                         className="w-100 px-2"
//                         id="school_support"
//                       >
//                         <option>اختر</option>
//                         <option value="دايماً">دايماً</option>
//                         <option value="احياناً">احياناً</option>
//                         <option value="لا ابدا">لا ابدا</option>
//                       </select>
//                     </div>
//                   </div>

//                   <div className="col-12 mb-md-3 my-5 d-flex flex-md-row flex-column gap-3">
//                     <label htmlFor="video_file" className="mb-2">
//                       تقدر من هنا تحمل فيديو أو صورة لك وأنت بتمارس موهبتك أو
//                       صورة لشهادة أو ميدالية استلمتها
//                     </label>
//                     <input
//                       type="file"
//                       required
//                       className="form-control"
//                       name="video_file"
//                       id="video_file"
//                       accept="video/*,image/*"
//                       onChange={handleChange}
//                     />
//                   </div>
//                 </div>
//                 <hr />
//                 <div className="row">
//                   <div className="col-12 col-md-6 mb-md-3 mb-4 d-flex flex-md-row flex-column gap-3">
//                     <button
//                       type="submit"
//                       className="btn-default border-0 px-4 py-2"
//                     >
//                       {" "}
//                       {t("signUpRegisterNow")}{" "}
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Signup;
